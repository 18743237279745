import {Injectable} from '@angular/core';
import {ColDef, IRowNode} from 'ag-grid-community';
import {CodeTableService} from './code-table.service';
import {
  genCodeTableColumn,
  genDateColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genTextColumn,
} from 'src/app/util/grid/grid-renderer.util';
import {ValueFormatterParams} from 'ag-grid-enterprise';
import {genIconColumn} from "../shared/grid/cell-renderers/icon.renderer";

@Injectable({
  providedIn: 'root',
})
export class GridService {

  constructor(protected codeTableService: CodeTableService) {}

  getPortfolioPositionAutoGroupColumnDef(_this: any): ColDef {
    return {
      headerName: _this.translateService.instant('assetClass'),
      cellRendererParams: {
        suppressCount: true
      },
      suppressHeaderMenuButton: true,
    };
  }

  getPortfolioPositionColDef(_this: any, userHubs: string[]): ColDef[] {
    return [
      {
        ...genCodeTableColumn({
          field: 'assetClass',
          headerName: _this.translateService.instant('assetClass'),
          filterHubs: () => userHubs,
        }),
        rowGroup: true,
        hide: true,
      },
      genCodeTableColumn({
        field: 'assetSubClass',
        headerName: _this.translateService.instant('assetSubClass'),
        filterHubs: () => userHubs,
      }),
      genCodeTableColumn({
        field: 'assetType',
        headerName: _this.translateService.instant('assetType'),
        filterHubs: () => userHubs,
      }),
      genTextColumn('assetName', _this.translateService.instant('name'), null, {
        customPath: 'asset.name',
      }),
      {
        ...genIconColumn({
          field: 'assetIsin',
          headerName: _this.translateService.instant('holdingIndicator'),
          icon: (data: any) => data?.isInCampaign ? 'warning' : undefined,
          tooltip: _this.translateService.instant('holdingIndicatorMsg'),
        }),
        comparator: (a: any, b: any, nodeA: IRowNode, nodeB: IRowNode) => {
          const x = nodeA.data?.isInCampaign ? 1 : 0;
          const y = nodeB.data?.isInCampaign ? 1 : 0;
          return x > y ? 1 : x < y ? -1 : 0;
        }
      },
      genTextColumn('assetIsin', _this.translateService.instant('isin'), null, {
        customPath: 'asset.isin',
      }),
      {
        ...genTextColumn(
          'currency.ident',
          _this.translateService.instant('currency'),
          null,
          {
            customPath: 'currency.ident',
          }
        ),
      },
      {
        ...genCodeTableColumn({
          field: 'rating',
          headerName: _this.translateService.instant('rating'),
          filterHubs: () => userHubs,
        }),
        hide: true,
      },
      {
        ...genDateColumn({
          field: 'nextCallDate',
          headerName: _this.translateService.instant('nextCallDate'),
          dateFormatter: (params: ValueFormatterParams) =>
            _this.globalService.dateToFrChLocale(params.value),
        }),
        hide: true,
      },
      {
        ...genDateColumn({
          field: 'maturityDate',
          headerName: _this.translateService.instant('maturityDate'),
          dateFormatter: (params: ValueFormatterParams) =>
            _this.globalService.dateToFrChLocale(params.value),
        }),
        hide: true,
      },
      {
        ...genNumberColumn(
          'quantity',
          _this.translateService.instant('quantity'),
          _this.globalService
        ),
        hide: true,
      },
      {
        ...genNumberColumn(
          'price',
          _this.translateService.instant('price'),
          _this.globalService
        ),
        hide: true,
      },
      {
        ...genNumberColumn(
          'totalValue',
          _this.labelBuilder.labelWithCurrency('totalValue'),
          _this.globalService
        ),
        aggFunc: 'sum',
      },
      {
        ...genPercentageNumberColumn(
          'percentage',
          _this.translateService.instant('percentage'),
          _this.globalService,
          true,
          false,
        ),
        floatingFilter: false, // percentage filter not working for in-memory row model
        aggFunc: 'sum',
      },
      {
        ...genPercentageNumberColumn(
          'profitAndLoss',
          _this.translateService.instant('performance'),
          _this.globalService,
          true,
          false,
        ),
        sortable: false,
        floatingFilter: false,
      }
    ];
  }
}

import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FieldDefinition, FieldDefinitionType} from "../../../../../api/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {EModalType} from "../../../../../util/enum";
import {ModalSubComponent} from "../../../../../models/modal.model";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-field-definition-dialog',
  templateUrl: './field-definition-dialog.component.html'
})
export class FieldDefinitionDialogComponent implements OnInit, OnDestroy, ModalSubComponent {
  readonly editorTypes = Object.values(FieldDefinitionType);

  readonly fieldDefinition: FieldDefinition;
  formGroup: FormGroup;
  ngUnsubscribe = new Subject<void>();

  constructor(
    protected dialogRef: MatDialogRef<ModalComponent>,
    protected readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
      fieldDefinition: FieldDefinition;
      fieldIdents: string[];
    } }
  ) {
    const fieldDefinition = this.fieldDefinition = data.data.fieldDefinition;
    const fieldIdents = data.data.fieldIdents;
    this.formGroup = this.fb.group({
      ident: new FormControl(
        fieldDefinition.ident,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern(/^[a-zA-Z0-9-_]+$/),
          this.identUniqueValidator(fieldIdents)
        ]
      ),
      labelEn: new FormControl(
        fieldDefinition.labelEn,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255)
        ]
      ),
      labelDe: new FormControl(
        fieldDefinition.labelDe,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255)
        ]
      ),
      description: new FormControl(
        fieldDefinition.description,
        Validators.maxLength(500)
      ),
      mandatory: new FormControl(
        fieldDefinition.mandatory,
        Validators.required
      ),
      editorType: new FormControl(
        fieldDefinition.editorType,
        Validators.required
      ),
      customEditable: new FormControl(
        fieldDefinition.customEditable,
        Validators.required
      ),
      maxLength: new FormControl(
        fieldDefinition.maxLength || null,
        [
          Validators.min(1),
          Validators.required
        ],
      ),
      maxLengthUnlimited: new FormControl(
        fieldDefinition.maxLength === null,
      ),
      heightInLines: new FormControl(
        fieldDefinition.heightInLines,
        [
          Validators.min(1),
          Validators.required
        ],
      ),
      showInGeneral: new FormControl(
        fieldDefinition.showInGeneral,
        Validators.required
      ),
      showInCustom: new FormControl(
        fieldDefinition.showInCustom,
        Validators.required
      ),
      editable: new FormControl(
        fieldDefinition.editable,
        Validators.required
      ),
    });
    if (fieldDefinition.id) {
      this.formGroup.get('ident').disable();
    }
    if (fieldDefinition.maxLength === null) {
      this.formGroup.get('maxLength').disable();
    }
    if (fieldDefinition.mandatory) {
      this.formGroup.get('showInGeneral').disable();
    }
  }

  ngOnInit() {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.formGroup.invalid;
    this.formGroup.statusChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.formGroup.invalid;
    });
    this.formGroup.get('maxLengthUnlimited').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value) {
          this.formGroup.get('maxLength').setValue(null, { emitEvent: false });
          this.formGroup.get('maxLength').disable({ emitEvent: false });
        } else {
          this.formGroup.get('maxLength').enable({ emitEvent: false });
        }
    });
    this.formGroup.get('mandatory').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value) {
          this.formGroup.get('showInGeneral').setValue(true, { emitEvent: false });
          this.formGroup.get('showInGeneral').disable({ emitEvent: false });
        } else {
          this.formGroup.get('showInGeneral').enable({ emitEvent: false });
        }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  identUniqueValidator(fieldIdents: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.fieldDefinition.ident.toUpperCase() == control.value.toUpperCase()) return null;
      return fieldIdents.includes(control.value.toUpperCase())
        ? { identNotUnique: true } : null;
    }
  }

  modalAction(modalType: EModalType | undefined) {
    const fieldDefinition = this.getFieldDefinitionFromForm();
    this.dialogRef.close(fieldDefinition);
  }

  private getFieldDefinitionFromForm(): FieldDefinition {
    return {
      id: this.fieldDefinition.id,
      ident: this.formGroup.get('ident').value,
      labelEn: this.formGroup.get('labelEn').value,
      labelDe: this.formGroup.get('labelDe').value,
      description: this.formGroup.get('description').value,
      mandatory: this.formGroup.get('mandatory').value,
      editorType: this.formGroup.get('editorType').value,
      customEditable: this.formGroup.get('customEditable').value,
      systemDefined: this.fieldDefinition.systemDefined,
      maxLength: this.formGroup.get('maxLength').value,
      heightInLines: this.formGroup.get('heightInLines').value,
      showInGeneral: this.formGroup.get('showInGeneral').value,
      showInCustom: this.formGroup.get('showInCustom').value,
      editable: this.formGroup.get('editable').value,
    };
  }

  protected readonly FieldDefinitionType = FieldDefinitionType;
}

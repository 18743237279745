import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, map, Observable, of, tap} from 'rxjs';
import {
  AdvisorsService,
  BusinessService,
  ClientService,
  CodeTableEntry,
  CodeTablesService,
  CurrencyService,
  RelationshipManagerService,
} from 'src/app/api/core';
import {ECodeTables} from 'src/app/util/enum';

type CodeTableLocalStorageEntry = {
  data: any[];
  expiryDate: string;
  language: string;
}

/**
 * Service for handling all code tables requests
 * Code tables are always cached until the end of the day for each language
 */
@Injectable({
  providedIn: 'root',
})
export class CodeTableService {
  constructor(
    protected codeTablesService: CodeTablesService,
    protected advisorService: AdvisorsService,
    protected businessService: BusinessService,
    protected clientService: ClientService,
    protected currencyService: CurrencyService,
    protected relationshipManagerService: RelationshipManagerService,
    protected translateService: TranslateService,
  ) {}

  getCodeTable(codeTable: ECodeTables): Observable<any[]> {
    const localStorageEntry = localStorage.getItem(codeTable);
    // check local storage
    if (localStorageEntry) {
      const item: CodeTableLocalStorageEntry = JSON.parse(localStorageEntry);
      const now = new Date();
      const expiryDate = new Date(item.expiryDate);
      if (
        item.language === this.translateService.currentLang &&
        now < expiryDate
      ) {
        // return correct language and not expired
        return of(item.data);
      } else {
        // remove expired or wrong language
        localStorage.removeItem(codeTable);
      }
    }
    // not found in local storage or not expired
    switch (codeTable) {
      case ECodeTables.advisoryType:
        return this.codeTablesService
          .getCodeTableEntries("ADVISORY_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.assetClass:
        return this.codeTablesService
          .getCodeTableEntries("ASSET_CLASS")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.assetSubClass:
        return this.codeTablesService
          .getCodeTableEntries("ASSET_SUBCLASS")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.assetType:
        return this.codeTablesService
          .getCodeTableEntries("ASSET_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.businessDivision:
        return this.codeTablesService
          .getCodeTableEntries("BUSINESS_DIVISION")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.businessUnit:
        return this.businessService
          .getUnits()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.channelType:
        return this.codeTablesService
          .getCodeTableEntries("CHANNEL_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.clientType:
        return this.codeTablesService
          .getCodeTableEntries("CLIENT_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.clientRole:
        return this.codeTablesService
          .getCodeTableEntries("CLIENT_ROLE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.continent:
        return this.codeTablesService
          .getCodeTableEntries("CONTINENT")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.country:
        return this.codeTablesService
          .getCodeTableEntries("COUNTRY")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.currency:
        return this.currencyService
          .getCurrencies()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.fidlegClientSegmentation:
        return this.codeTablesService
          .getCodeTableEntries("FIDLEG")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.gender:
        return this.codeTablesService
          .getCodeTableEntries("GENDER")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.gics:
        return this.codeTablesService
          .getCodeTableEntries("GICS")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.hobby:
        return this.codeTablesService
          .getCodeTableEntries("HOBBY")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.hub:
        return this.codeTablesService
          .getCodeTableEntries("HUB")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.language:
        return this.codeTablesService
          .getCodeTableEntries("LANGUAGE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.mifidClientSegmentation:
        return this.codeTablesService
          .getCodeTableEntries("MIFID")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.portfolioStrategy:
        return this.codeTablesService
          .getCodeTableEntries("PORTFOLIO_STRATEGY")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.portfolioType:
        return this.codeTablesService
          .getCodeTableEntries("PORTFOLIO_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.productInvestmentHorizon:
        return this.codeTablesService
          .getCodeTableEntries("PRODUCT_INVESTMENT_HORIZON")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.productRatingApac:
        return this.codeTablesService
          .getCodeTableEntries("PRODUCT_RATING_APAC")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.productRatingMe:
        return this.codeTablesService
          .getCodeTableEntries("PRODUCT_RATING_ME")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ranking:
        return this.codeTablesService
          .getCodeTableEntries("RANKING")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.rating:
        return this.codeTablesService
          .getCodeTableEntries("RATING")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingMoody:
        return this.codeTablesService
          .getCodeTableEntries("RATING_MOODY")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingSource:
        return this.codeTablesService
          .getCodeTableEntries("RATING_SOURCE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingSp:
        return this.codeTablesService
          .getCodeTableEntries("RATING_SP")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingSustainability:
        return this.codeTablesService
          .getCodeTableEntries("SUSTAINABILITY_RATING")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.region:
        return this.codeTablesService
          .getCodeTableEntries("REGION")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.riskState:
        return this.codeTablesService
          .getCodeTableEntries("RISK_STATE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.rmMarket:
        return this.codeTablesService
          .getCodeTableEntries("RELATIONSHIP_MANAGER_MARKET")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.serviceCenter:
        return this.codeTablesService
          .getCodeTableEntries("SERVICE_CENTER")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.sustainabilityProfile:
        return this.codeTablesService
          .getCodeTableEntries("SUSTAINABILITY_PROFILE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.useCase:
        return this.codeTablesService
          .getCodeTableEntries("USE_CASE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.viewedStatus:
        return this.codeTablesService
          .getCodeTableEntries("VIEWED_STATUS")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.publicationType:
        return this.codeTablesService
          .getCodeTableEntries("PUBLICATION_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.relManager:
        // MODIFY DATA TO CODE TABLE DATA STRUCTURE
        return this.relationshipManagerService.getRelationshipManagers().pipe(
          tap((data) =>
            this.writeCodeTableToLocalStorage(
              codeTable,
              data.map((x) => ({
                id: x.id,
                ident: x.username,
                name: x.fullname,
              }))
            )
          ),
          map((data) => data.map((x) => ({
              id: x.id,
              ident: x.username,
              name: x.fullname,
            })))
        );
      case ECodeTables.relAdvisor:
        // MODIFY DATA TO CODE TABLE DATA STRUCTURE
        return this.advisorService.getAdvisors().pipe(
          tap((data) =>
            this.writeCodeTableToLocalStorage(
              codeTable,
              data.map((x) => ({
                id: x.id,
                ident: x.username,
                name: x.fullname,
              }))
            )
          ),
          map((data) => data.map((x) => ({
              id: x.id,
              ident: x.username,
              name: x.fullname,
            })))
        );
      default:
        return EMPTY;
    }
  }

  private writeCodeTableToLocalStorage(
    codeTable: ECodeTables,
    data: any[]
  ): void {
    const expiryDate = new Date();
    expiryDate.setHours(24, 0, 0, 0);
    const item = {
      data,
      expiryDate,
      language: this.translateService.currentLang,
    };
    localStorage.setItem(codeTable, JSON.stringify(item));
  }
}

export function clearCodeTablesFromLocalStorage() {
  const codeTables = Object.keys(ECodeTables);
  Object.keys(localStorage).forEach((key) => {
    if (codeTables.includes(key)) {
      localStorage.removeItem(key);
    }
  });
}
/**
 * Returns a list of code table entries with the given entry added if it is not already in the list.
 * @param entries
 * @param entry
 * @private
 */
export function codeTableEntries(entries: CodeTableEntry[], entry?: CodeTableEntry | CodeTableEntry[]): CodeTableEntry[] {
  let result = entries.filter(e => !e.closed);
  if (!entry) return result;
  // check if entry is an array
  const sort = (a, b) => a.name.localeCompare(b.name);
  if (Array.isArray(entry)) {
    const entries = entry as CodeTableEntry[];
      entries.forEach(e => {
        if (!result.find(it => it.id === e.id)) {
          result.push(e)
        }
      });
    result.sort(sort);
  } else {
    if (!result.find(e => e.id === entry.id)) {
      result.push(entry);
      result.sort(sort);
    }
  }
  return result;
}

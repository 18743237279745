import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ISliderOptions} from 'src/app/models/slider-options.model';
import {FilterMetaData} from '../../../models/filter-meta-data.model';
import {EFilterSubType, EFilterType} from '../../../util/enum';
import {AssetRange, UnbundledType, UnbundledTypes} from 'src/app/models/filter.model';

type AsEnum = 'value' | 'percentage' | 'hasExposure' | 'performance';
const asEnum = {
  Value: 'value' as AsEnum,
  Percentage: 'percentage' as AsEnum,
  HasExposure: 'hasExposure' as AsEnum,
  performance: 'performance' as AsEnum,
};

/**
 * Range Weight Filter Component.
 * Component for combination of range and weight filter.
 */
@Component({
  selector: 'app-filter-asset',
  templateUrl: './filter-asset.component.html',
})
export class FilterAssetComponent implements OnInit {
  /**
   * Component input to set filter value
   */
  @Input() filter: AssetRange;

  @Input() readOnly = false;
  /**
   * Component output to notify that filter should be deleted
   */
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @angular-slider/ngx-slider range slider options
   */
  rangeSlider: ISliderOptions;
  /**
   * Flag to mark to show/hide range slider
   */
  asSelect: AsEnum;
  asOptions: AsEnum[] = Object.values(asEnum);
  assetName = '';
  assetLabel = '';
  minValue = 0;
  maxValue = 1;
  percMeta: FilterMetaData = new FilterMetaData(
    EFilterType.range,
    '',
    EFilterSubType.rangeMinMaxPercentage
  );
  valueMeta: FilterMetaData = new FilterMetaData(
    EFilterType.range,
    '',
    EFilterSubType.rangeMinMax
  );
  unbundledTypes = [...UnbundledTypes];
  unbundledType: UnbundledType = 'direct';
  unbundledTypeText: Record<UnbundledType, string> = {
    direct: 'exposureDirect',
    unbundled: 'exposureUnbundled',
    underlying: 'exposureUnderlying'
  }

  constructor() {}

  ngOnInit(): void {
    this.fromFilter();
  }

  private fromFilter() {
    this.assetName = this.filter.key;
    this.assetLabel = this.filter.name;
    this.asSelect = this.filter.isValue ? 'value' :
      this.filter.hasExposure ? 'hasExposure' :
        this.filter.hasPerformance ? 'performance' :
          'percentage';
    if (this.filter.isValue) {
      this.minValue = this.filter.range.min ?? 0;
      this.maxValue = this.filter.range.max ?? 1;
    } else if (!this.filter.hasExposure) {
      this.rangeSlider = this.createRangeSliderConfig({
        min: this.filter.range.min ? this.filter.range.min * 100 : 0,
        max: this.filter.range.max ? this.filter.range.max * 100 : 100,
      });
    }
    this.unbundledType = this.filter.unbundledType;
    this.onAsChanged();
  }

  /**
   * Generate initial @angular-slider/ngx-slider options
   * @param cfg Config
   */
  private createRangeSliderConfig(cfg: ISliderOptions): ISliderOptions {
    return {
      minValue: 0,
      maxValue: 100,
      step: 1,
      disabled: this.readOnly,
      formatLabel: (value: number): string => value + '%',
      ...cfg
    };
  }

  onAsChanged(): void {
    this.filter.isValue = this.asSelect === 'value';
    this.filter.hasExposure = this.asSelect === 'hasExposure';
    if (this.asSelect === 'performance') {
      this.unbundledType = 'direct';
      this.onUnbundledChanged();
    }
    this.filter.hasPerformance = this.asSelect === 'performance';
  }
  onUnbundledChanged() {
    this.filter.unbundledType = this.unbundledType;
  }

  /**
   * Click handler on delete icon
   */
  onDeleteClick(): void {
    this.delete.emit();
  }

  /**
   * Helper funtion to track item during iteration over items
   * @param index Index of item
   * @param item Iterated item
   */
  trackByFn(index: number, item: any): number {
    return index;
  }

  clamp(low: number, high: number, value: number): number {
    return Math.min(high, Math.max(low, value));
  }

  formHasErrors(hasErrors: boolean): void {
    this.filter.formHasErrors = hasErrors;
  }
}

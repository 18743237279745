import {Component, Inject} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../../services/global.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {CodeTableEntry, Disclaimer, DisclaimerAssignment, DisclaimerService} from "../../../../../api/core";
import {ModalSubComponent} from "../../../../../models/modal.model";
import {ECodeTables, EModalType} from "../../../../../util/enum";
import {combineLatest} from "rxjs";
import {NotificationService} from "../../../../../services/notification.service";
import {MatSelectChange} from "@angular/material/select";
import {CodeTableService} from "../../../../../services/code-table.service";

@Component({
  selector: 'app-disclaimer-assignment-dialog',
  templateUrl: 'disclaimer-assignment-dialog.component.html'
})
export class DisclaimerAssignmentDialogComponent implements ModalSubComponent {

  disclaimers: Disclaimer[];
  hubs: CodeTableEntry[];
  publicationTypes: CodeTableEntry[];
  assignment: DisclaimerAssignment | undefined;

  formGroup: FormGroup;

  constructor(
    readonly fb: FormBuilder,
    readonly translateService: TranslateService,
    readonly globalService: GlobalService,
    readonly notificationService: NotificationService,
    readonly disclaimerService: DisclaimerService,
    readonly codeTableService: CodeTableService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
      assignment?: DisclaimerAssignment
      } }
  ) {
    this.assignment = data.data.assignment;
    this.formGroup = fb.group({
      disclaimerId: new FormControl({value: 0, disabled: false}, Validators.required),
      hubId: new FormControl({value: 0, disabled: !!this.assignment}, Validators.required),
      publicationTypeId: new FormControl({value: 0, disabled: !!this.assignment}),
    });
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.hub),
      this.codeTableService.getCodeTable(ECodeTables.publicationType),
      this.disclaimerService.getDisclaimers({firstResult: 0, pageSize: 100}),
    ]).subscribe(([hubs, pTypes, disclaimers]) => {
      this.disclaimers = disclaimers.pageItems;
      this.hubs = hubs;
      this.publicationTypes = pTypes;
      this.formGroup.patchValue({
        disclaimerId: this.assignment?.disclaimer?.id || 0,
        hubId: this.assignment?.hub?.id || 0,
        publicationTypeId: this.assignment?.publicationType?.id || 0
      });
    });
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
  }

  modalAction(modalType: EModalType) {
    const values = this.formGroup.getRawValue();
    const {disclaimerId, hubId, publicationTypeId} = values;
    if (!disclaimerId || !hubId) return;
    const disclaimer = this.disclaimers.find(d => d.id === +disclaimerId);
    const hub = this.hubs.find(h => h.id === +hubId);
    const publicationType = this.publicationTypes.find(pt => pt.id === +(publicationTypeId || 0));
    this.dialogRef.close({id: this.assignment?.id, disclaimer, hub, publicationType});
  }

  onSelectChanged(e: MatSelectChange) {
    const values = this.formGroup.getRawValue();
    const {disclaimerId, hubId} = values;
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !disclaimerId || !hubId;
  }

}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {CodeTableEntry} from "../../../../api/core";
import {CodeTableService} from "../../../../services/code-table.service";
import {ECodeTables} from "../../../../util/enum";
import {combineLatest} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";

export type HubLanguageSelection = {
  hub:CodeTableEntry;
  language: CodeTableEntry;
};

@Component({
  selector: 'app-hub-languages',
  templateUrl: './hub-languages.component.html'
})
export class HubLanguagesComponent implements OnInit, OnChanges {
  @Input()
  selected: HubLanguageSelection[];
  @Output()
  selectedChanged = new EventEmitter<HubLanguageSelection>();

  hubs: CodeTableEntry[];
  languages: CodeTableEntry[];
  formGroup: FormGroup;


  constructor(
    readonly codeTableService: CodeTableService,
    readonly fb: FormBuilder,
  ) {
    combineLatest([
      codeTableService.getCodeTable(ECodeTables.hub),
      codeTableService.getCodeTable(ECodeTables.language)
    ]).subscribe(([hubs, languages]) => {
      this.hubs = hubs;
      this.languages = languages;
      const group = {};
      hubs.forEach(hub => {
        group[hub.ident] = [null, Validators.required];
      })
      this.formGroup = fb.group(group);
    });
  }

  ngOnInit() {
    this.onSelectedChanged();
  }

  ngOnChanges(changes: SimpleChanges) {
    const values = this.formGroup.getRawValue();
    if (changes.selected) {
      this.onSelectedChanged();
    }
  }

  private onSelectedChanged() {
    const values = {};
    this.hubs.forEach(hub => {
      const lang = this.selected.find(it => it.hub.id == hub.id)?.language;
      values[hub.ident] = this.languages.find(it => it.id == lang?.id);
    });
    this.formGroup.patchValue(values);
  }

  onLangChanged(e: MatSelectChange) {
    const hubIdent = e.source.ngControl.name as string;
    const hub = this.hubs.find(it => it.ident == hubIdent);
    const language = this.languages.find(it => it.id == e.value?.id);
    if (hub && language) {
      this.selectedChanged.emit({hub, language});
    }
  }

}

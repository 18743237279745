import {Component, OnDestroy, OnInit} from "@angular/core";
import {CAMPAIGN_OVERVIEW_PREVIEW_TABS} from "../../../util/tab.constants";
import {Tab} from "../../../models/tabs.model";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {EViewRoutes} from "../../../util/enum";
import {RedirectArg, RedirectConfig, redirectConfig, RedirectTarget} from "../../../shared/redirect/redirect.component";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Observable, of, Subscription} from "rxjs";
import {
  CampaignService,
  ClientService,
  EmployeeService,
  IntermediaryService,
  PortfolioService,
  User
} from "../../../api/core";
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from "rxjs/operators";

type FilterItem = {
  key: string;
  tooltip: string;
}

type IdentItem = {
  ident: string;
  name: string;
}

@Component({
  selector: 'app-link-creator',
  templateUrl: './link-creator.component.html'
})
export class LinkCreatorComponent implements OnInit, OnDestroy {
  readonly targets: RedirectTarget[] = [
    'campaign-intermediaries' ,
    'campaign-list' ,
    'campaign-overview' ,
    'campaign-overview-flat' ,
    'client-list' ,
    'client-overview' ,
    'employee-list' ,
    'employee-overview' ,
    'intermediary-list' ,
    'intermediary-overview',
    'portfolio-list' ,
    'portfolio-overview' ,
  ];

  formGroup = this.fb.group({
    link: [location.origin],
    target: [null as RedirectTarget],
    ident: ['', Validators.maxLength(255)],

    actualLanguage: ['', Validators.maxLength(100)],
    advisor: ['', Validators.maxLength(255)],
    advisoryType: ['', Validators.maxLength(100)],
    assignee: ['', Validators.maxLength(255)],
    bpNumber: ['', Validators.maxLength(100)],
    channelType: ['', Validators.maxLength(100)],
    clientRole: ['', Validators.maxLength(100)],
    closed: ['', Validators.maxLength(100)],
    createdAt: ['', Validators.maxLength(100)],
    currency: ['', Validators.maxLength(100)],
    done: ['', Validators.maxLength(100)],
    externalKey: ['', Validators.maxLength(100)],
    hub: ['', Validators.maxLength(100)],
    intermediaryType: ['', Validators.maxLength(100)],
    language: ['', Validators.maxLength(100)],
    leadRelationshipManager: ['', Validators.maxLength(100)],
    name: ['', Validators.maxLength(255)],
    number: ['', Validators.maxLength(100)],
    optOut: ['', Validators.maxLength(100)],
    portfolioNumber: ['', Validators.maxLength(100)],
    portfolioStrategy: ['', Validators.maxLength(100)],
    portfolioType: ['', Validators.maxLength(100)],
    portfolioValue: ['', Validators.maxLength(100)],
    preferredLanguage: ['', Validators.maxLength(100)],
    processing: ['', Validators.maxLength(100)],
    relationshipManager: ['', Validators.maxLength(100)],
    risk: ['', Validators.maxLength(100)],
    riskMax: ['', Validators.maxLength(100)],
    riskMin: ['', Validators.maxLength(100)],
    riskState: ['', Validators.maxLength(100)],
    sender: ['', Validators.maxLength(100)],
    status: ['', Validators.maxLength(100)],
    story: ['', Validators.maxLength(100)],
    suitability: ['', Validators.maxLength(100)],
    validFrom: ['', Validators.maxLength(100)],
    validTo: ['', Validators.maxLength(100)],
    value: ['', Validators.maxLength(100)],
    viewed: ['', Validators.maxLength(100)],
  });
  identControl = new FormControl('');

  config: RedirectConfig;
  target: RedirectTarget;
  filterLabels: Record<RedirectTarget, Record<string, string>> = {
    'campaign-list': {
      name: 'any text value, will filter by campaign name',
      story: 'any text value, will filter by story name',
      status: 'any of [DRAFT, FROZEN, LAUNCHED, CLOSED, TERMINATED] (CSV)',
      processing: 'one of [true, false]',
      createdAt: 'date formatted text value, supported formats are [dd.MM.yyyy,yyyy-MM-dd]',
      validFrom: 'date formatted text value, supported formats are [dd.MM.yyyy,yyyy-MM-dd]',
      validTo: 'date formatted text value, supported formats are [dd.MM.yyyy,yyyy-MM-dd]'
    },
    'campaign-overview': {
      portfolioNumber: 'any text value',
      done: 'any of [true, false] (CSV)',
      portfolioType: 'ident text value, client dependent',
      advisoryType: 'ident text value, client dependent',
      portfolioStrategy: 'ident text value, client dependent',
      currency: 'ISO-3 currency code (CSV)',
      riskState: 'any of [within, under, over] (CSV)',
      bpNumber: 'any text value',
      language: 'ISO-2 language code (CSV)',
      relationshipManager: 'user name (short, CSV)',
      advisor: 'user name (short, CSV)',
      assignee: 'user name (short, CSV)',
      suitability: 'any of [OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING] (CSV)',
    },
    'campaign-overview-flat': {
      portfolioNumber: 'any text value',
      preferredLanguage: 'ISO-2 language code (CSV)',
      actualLanguage: 'ISO-2 language code (CSV)',
      bpNumber: 'any text value',
      channelType: 'any of [email, ebanking] (CSV)',
      relationshipManager: 'user name (short, CSV)',
      advisor: 'user name (short, CSV)',
      assignee: 'user name (short, CSV)',
      sender: 'user name (short, CSV)',
      suitability: 'any of [OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING] (CSV)',
      status: 'any of [DUPLICATE_CLIENT, NO_ACTION, PENDING, SENT] (CSV)',
      viewed: 'any of [no, not_sent, yes] (CSV)',
    },
    'campaign-intermediaries': {
      name: 'any text value',
      externalKey: 'any text value',
      intermediaryType: '[eam, ewa]',
      hub: 'Hub ident (CSV)',
      leadRelationshipManager: 'user name (short, CSV)',
      closed: 'any of [no, yes] (CSV)',
    },
    'portfolio-list': {
      number: 'any text value',
      portfolioType: 'ident text value, client dependent',
      advisoryType: 'ident text value, client dependent',
      portfolioStrategy: 'ident text value, client dependent',
      currency: 'ISO-3 currency code (CSV)',
      riskState: 'any of [within, under, over] (CSV)',
      bpNumber: 'any text value',
      language: 'ISO-2 language code (CSV)',
      relationshipManager: 'user name (short, CSV)',
      advisor: 'user name (short, CSV)',
      clientRole: 'ident text value, client dependent',
      optOut: 'one of [true, false]',
      closed: 'one of [true, false]',
    },
    'portfolio-overview': {},
    'client-list': {},
    'client-overview': {},
    'employee-list': {},
    'employee-overview': {},
    'intermediary-list': {},
    'intermediary-overview': {},
  }
  filterItems: FilterItem[];
  paramItems: FilterItem[];
  filteredIdents$: Observable<IdentItem[]>;
  subscriptions: Subscription[] = [];
  showIdent = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly campaignService: CampaignService,
    private readonly portfolioService: PortfolioService,
    private readonly clientService: ClientService,
    private readonly employeeService: EmployeeService,
    private readonly intermediaryService: IntermediaryService,
  ) {
  }

  ngOnInit() {
    this.filteredIdents$ = this.identControl.valueChanges.pipe(
      filter((val: string) => val && val.length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => {
        switch (this.target) {
          case 'campaign-overview':
          case 'campaign-overview-flat':
          case 'campaign-intermediaries':
            return this.campaignService.getCampaigns({
              firstResult: 0, pageSize: 10, search: val
            }).pipe(
              map(page => page.pageItems.map(c => ({ident: c.ident, name: c.name})))
            );
          case 'portfolio-overview':
            return this.portfolioService.getPortfolios({
              firstResult: 0, pageSize: 10, search: val
            }).pipe(
              map(page => page.pageItems.map(p => ({ident: p.ident, name: p.number})))
            );
          case 'client-overview':
            return this.clientService.getClients({
              firstResult: 0, pageSize: 10, filter: `personNumber=like ${val}`
            }).pipe(
              map(page => page.pageItems.map(c => ({ident: c.ident, name: c.personNumber})))
            );
          case 'employee-overview':
            return this.employeeService.getEmployees({
              firstResult: 0, pageSize: 10, filter: `externalKey=like ${val}`
            }).pipe(
              map(page => page.pageItems.map(e => ({ident: e.ident, name: e.externalKey})))
            );
          case 'intermediary-overview':
            return this.intermediaryService.getIntermediaries({
              firstResult: 0, pageSize: 10, filter: `externalKey=like ${val}`
            }).pipe(
              map(page => page.pageItems.map(i => ({ident: i.ident, name: i.externalKey})))
            );
          default:
            return of([]);
        }
      })
    );
    this.subscriptions.push(
      this.identControl.valueChanges.subscribe(() => {
        if (this.formGroup.value.ident != null) {
          this.formGroup.patchValue({ident: null});
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get visibleParams() {
    return (this.paramItems || []).filter(p => p.key != 'ident');
  }

  updateTarget() {
    const values = this.formGroup.getRawValue();
    this.target = values.target;
    this. config = redirectConfig.find(c => c.target == this.target);
    const patch: Record<string, string> = {};
    this.filterItems = (this.config?.filters || []).map(f => {
      patch[f.name] = '';
      return {
        key: f.name,
        tooltip: this.filterLabels[this.target][f.name] || '',
      }
    }).sort((a,b) => a.key.localeCompare(b.key));
    this.paramItems = (this.config?.params || [])
      .map(p => {
        patch[p.name] = '';
        return {
          key: p.name,
          tooltip: p.name,
        }
      });
    this.showIdent = !!this.paramItems.find(p => p.key == 'ident');
    this.formGroup.patchValue(patch);
    this.updateLink();
  }
  updateLink() {
    const values = this.formGroup.getRawValue();
    const target = values.target;
    const search = new URLSearchParams({target});
    const filters = this.filterItems
      .filter(f => values[f.key])
      .map(f => `${f.key}=${values[f.key]}`)
      .join(';');
    const params = this.paramItems
      .filter(p => values[p.key])
      .map(p => `${p.key}=${values[p.key]}`)
      .join(';');
    if (params) search.append('params', params);
    if (filters) search.append('filters', filters);
    const link = `${location.origin}/redirect?${search.toString()}`;
    this.formGroup.patchValue({link});
  }

  onIdentSelect(event: MatAutocompleteSelectedEvent): void {
    this.formGroup.patchValue({ident: event.option.value});
    this.identControl.setValue(event.option.value, {
      onlySelf: true,
      emitEvent: false,
    });
    setTimeout(() => this.updateLink());
  }
}

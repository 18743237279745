import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import {
  Product,
  ProductEdit,
  ProductService,
  Tag,
  TagService,
} from 'src/app/api/core';
import { ModalSubComponent } from 'src/app/models/modal.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { EFormStatus, EModalType } from 'src/app/util/enum';
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

/**
 * Component to show the product details (can handle create and edit).
 */
@Component({
  selector: 'app-product-details-form',
  templateUrl: './product-details-form.component.html',
})
export class ProductDetailsFormComponent
  implements OnInit, OnDestroy, ModalSubComponent
{
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  subscriptions: Subscription[] = [];
  product: Product;
  productForm = this.fb.group({
    customDescription: ['', Validators.maxLength(10000)],
    tags: this.fb.array([] as Tag[], this.tagsRequired),
  });

  tagControl = new FormControl();
  filteredTags$: Observable<Tag[]>;

  actionButtonLabel = 'Create';

  constructor(
    private fb: FormBuilder,
    protected productService: ProductService,
    protected tagService: TagService,
    protected globalService: GlobalService,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: { product: Product } }
  ) {
    this.handleProduct(data.data?.product);
  }

  ngOnInit(): void {
    this.filteredTags$ = this.tagControl.valueChanges.pipe(
      filter((val: string) => val && val.length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => {
        if (!this.doesTagExist(val)) {
          return this.tagService
            .searchTags(val)
            .pipe(
              map((tags) => tags.filter((t) => !this.doesTagExist(t.name)))
            );
        } else {
          return of([]);
        }
      })
    );
    this.subscriptions.push(
      this.productForm.statusChanges.subscribe(
        (status) =>
          (this.dialogRef.componentInstance.toolbarActionData.btnDisabled =
            status === EFormStatus.INVALID)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get tags() {
    return this.productForm.controls.tags as FormArray;
  }

  addTag(tag: Tag): void {
    if (this.doesTagExist(tag.name)) {
      return;
    }
    const productTagForm = this.fb.group({
      id: [null as number, Validators.required],
      name: ['', Validators.required],
    });
    if (tag) {
      productTagForm.patchValue({ ...tag });
    }
    this.tags.push(productTagForm);
  }

  doesTagExist(value: string): boolean {
    return (
      this.tags.getRawValue().findIndex((tag) => tag.name === value) !== -1
    );
  }

  getOrCreateTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.doesTagExist(value)) {
      this.tagService.getOrCreateTag(value).subscribe({
        next: (tag) => {
          // ToDo handle errors
          this.addTag(tag);
        },
      });
    }
    event.chipInput.clear();
  }

  removeTag(tag: Tag): void {
    const tags = this.productForm.value.tags;
    tags.forEach((item, index) => {
      if (item === tag) {
        this.tags.removeAt(index);
      }
    });
  }

  onTagSelect(event: MatAutocompleteSelectedEvent): void {
    this.tagInput.nativeElement.value = '';
    if (event.option?.value) {
      this.addTag(event.option.value);
      this.tagControl.setValue('');
    }
  }

  private handleProduct(product: Product): void {
    if (product) {
      this.actionButtonLabel = 'Save';
      this.product = product;
      this.productForm.patchValue({
        customDescription: product.customDescription,
      });
      // add tags
      product.tags.forEach((t) => this.addTag(t));
    }
  }

  private tagsRequired(formControl: AbstractControl): ValidationErrors {
    if (this.product) {
      Validators.required(formControl);
    } else {
      return null;
    }
  }

  /**
   * Triggered by parent component product-modal.component
   */
  modalAction(modalType: EModalType): void {
    switch (modalType) {
      case EModalType.editProduct:
        const editProduct: ProductEdit = {
          ...this.product,
          ...this.productForm.getRawValue(), // TODO check if this is correct (rawValue might return too much data)
        };
        this.productService
          .updateProduct(editProduct)
          .pipe(
            first(),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: (productData) => {
              this.dialogRef.close({ success: true, product: productData });
            },
          });
        break;
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CodeTableEntry, MonitoringBucket, MonitoringCampaign, MonitoringService, Story} from 'src/app/api/core';
import {MONITORING_TABS} from "../../../util/tab.constants";
import {Tab} from "../../../models/tabs.model";
import {EViewRoutes} from "../../../util/enum";
import {EProtectedActions} from "../../../util/protected-actions";
import {Subject} from "rxjs";
import {HtmlService} from "../../../services/html.service";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
})
export class MonitoringComponent implements OnInit {
  refreshSubject = new Subject<string>();
  selectedBucketSubject = new Subject<MonitoringBucket>();
  allowedRoles: string[];
  viewRoute: string;
  monitoringCampaign: MonitoringCampaign;
  story: Story;
  myBuckets: MonitoringBucket[] = [];
  buckets: MonitoringBucket[] = [];
  hubs: CodeTableEntry[] = [];
  selectedHub: CodeTableEntry;
  selectedBucket: MonitoringBucket;

  tabs: Tab[] = MONITORING_TABS;

  defaultTab: string;
  activeTab: string;
  hasHubs = false;
  isAdmin = false;
  isDecentralizedMonitor = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected readonly htmlService: HtmlService,
    protected readonly monitoringService: MonitoringService,
    private readonly permissionService: PermissionService,
    ) {
    this.isAdmin = this.permissionService.hasAnyPermission(EProtectedActions.isAdmin);
    this.isDecentralizedMonitor = this.permissionService.hasAnyPermission(EProtectedActions.decentralizedCampaignMonitoring)
    this.tabs = this.tabs.map(tab => {
      return {...tab, hidden: !this.permissionService.hasAnyPermission(tab.permission)}
    });
    this.updateDefaultTab();
    activatedRoute.data.subscribe((data) => {
      this.allowedRoles = data.allowedRoles;
      this.viewRoute = data.viewRoute;
      this.monitoringCampaign = data?.campaign;
      this.story = data?.story;
      if (data.viewRoute === EViewRoutes.monitoringCampaignOverview) {
        this.tabs = this.tabs.map(tab => ({...tab, hidden: false}))
        this.updateDefaultTab();
      }
      if (data.viewRoute === EViewRoutes.monitoringCampaignDecentralizedOverview) {
        this.tabs = this.tabs.map(tab => ({...tab, hidden: false}))
        this.updateDefaultTab();
      }
    });
    this.monitoringService.getMyBuckets().subscribe(buckets => {
      this.myBuckets = buckets;
      this.hubs = Array.from(new Set(buckets.map(b => b.hub.id)))
        .map(hubId => buckets.find(b => b.hub.id == hubId).hub);
      this.onSelectHub(this.hubs[0]);
      this.hasHubs = this.hubs.length > 1;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      const pageBody = this.htmlService.getElem('section.page-body');
      const pageBodyHeight = this.htmlService.getHeight('', 'section.page-header');
      this.htmlService.addStyle(pageBody, 'max-height', this.htmlService.getValue(pageBodyHeight));
      const appTabsPanel = this.htmlService.getElem('app-monitoring > .page-content-container > app-tabs-panel');
      const tabContainer = this.htmlService.getElem('app-monitoring > .page-content-container > .tab-container');
      const tabContainerHeight = pageBodyHeight - appTabsPanel.clientHeight;
      this.htmlService.addStyle(tabContainer, 'max-height', this.htmlService.getValue(tabContainerHeight));
    }, 0);
  }

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }

  get protectedActions() {
    return EProtectedActions;
  }

  get isTabDecentralizedOverview() {
    return this.activeTab === this.tabs[2].text
  }

  handleUpdate() {
    this.refreshSubject.next('refreshData');
  }

  onSelectHub(hub: CodeTableEntry) {
    this.selectedHub = hub;
    this.buckets = this.myBuckets.filter(b => b.hub.id === hub.id);
    this.selectedBucket = this.buckets[0];
    this.selectedBucketSubject.next(this.selectedBucket);
  }

  onSelectBucket(bucket: MonitoringBucket) {
    this.selectedBucket = bucket;
    this.selectedBucketSubject.next(this.selectedBucket);
  }

  /**
   * Updates the default and active tab based on the visible tabs.
   * @private
   */
  private updateDefaultTab() {
    this.defaultTab = this.tabs.find(tab => !tab.hidden)?.text;
    this.activeTab = this.defaultTab;
  }

  protected readonly EProtectedActions = EProtectedActions;
}

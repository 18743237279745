import {FilterAdvanced, FilterAdvancedField, FilterBody} from '../api/core';
import {
  AssetRange,
  CompositeList,
  FilterClient,
  FilterConfig,
  FilterIntermediaries,
  FilterOrgPosition,
  FilterPortfolio,
  FilterPosition,
  UnbundledType,
  WeightRange
} from '../models/filter.model';
import {
  fnaAssetClassValue,
  fnaAssetClassWeight,
  fnaClientAge,
  fnaClientDomicile,
  fnaClientGender,
  fnaCurrencyValue,
  fnaCurrencyWeight,
  fnaExcludeEAM,
  fnaExcludeEWA,
  fnaPortfolioAdvisor,
  fnaPortfolioAdvisoryType,
  fnaPortfolioAllowOptOut,
  fnaPortfolioBusinessDivision,
  fnaPortfolioBusinessUnit,
  fnaPortfolioFidleg,
  fnaPortfolioLiquidity,
  fnaPortfolioManager,
  fnaPortfolioMifid,
  fnaPortfolioQualifiedInvestor,
  fnaPortfolioReferenceCurrency,
  fnaPortfolioReturn,
  fnaPortfolioRisk,
  fnaPortfolioRiskProfile,
  fnaPortfolioRiskState,
  fnaPortfolioRmDepartment,
  fnaPortfolioRmLocation,
  fnaPortfolioRmMarket,
  fnaPortfolioServiceCenter,
  fnaPortfolioSustainabilityProfile,
  fnaPortfolioType,
  fnaPortfolioValue,
  fnaPortfolioWaiveOfAdvice,
  fnaPositionClass,
  fnaPositionIds,
  fnaPositionIsin,
  fnaPositionIsinTotalValue,
  fnaPositionIssuerName,
  fnaPositionMaturity,
  fnaPositionMoodyRating,
  fnaPositionNextCall,
  fnaPositionPerformance,
  fnaPositionProductInvestmentHorizon,
  fnaPositionProductRatingApac,
  fnaPositionProductRatingMe, fnaPositionProfitAndLoss,
  fnaPositionRanking,
  fnaPositionRatingSourceLGT,
  fnaPositionRatingSP,
  fnaPositionSubClass,
  fnaPositionSustainabilityRating,
  fnaPositionType,
  fnaRegionValue,
  fnaRegionWeight,
  fnaSectorValue,
  fnaSectorWeight
} from './filter.names';
import {EFilterCategories} from "../util/enum";
import WeightEnum = WeightRange.WeightEnum;

export function convertFilterBodyToConfig(filterBody: FilterBody): FilterConfig {
  const advanced = filterBodyFieldsToAdvanced(filterBody?.advanced);
  const filterConfig: FilterConfig = {
    id: filterBody?.id,
    client: filterBodyFieldsToClient(filterBody.standard),
    intermediaries: filterBodyFieldsToIntermediaries(filterBody.standard),
    portfolio: filterBodyFieldsToPortfolio(filterBody.standard),
    position: filterBodyFieldsToPosition(filterBody.standard),
    assetsInclude: filterBodyFieldsToAssetsInclude(filterBody.standard),
    assetsExclude: filterBodyFieldsToAssetsExclude(filterBody.standard),
    assetClass: filterBodyFieldsToAssetClass(filterBody.standard),
    currency: filterBodyFieldsToCurrency(filterBody.standard),
    gics: filterBodyFieldsToGics(filterBody.standard),
    region: filterBodyFieldsToRegion(filterBody.standard),
    orgPosition: filterBodyFieldsToOrgPosition(filterBody.standard),
    advanced
  };
  return logBodyConfig(filterBody, filterConfig);
}

function logBodyConfig(filterBody: FilterBody, filterConfig: FilterConfig): FilterConfig {
  // console.log('body-config', {filterBody, filterConfig});
  return filterConfig;
}

function filterBodyFieldsToAdvanced(source: FilterAdvanced): any {
  if (source?.metadata !== '') {
    return source;
  }
  return undefined;
}

function filterBodyFieldsToClient(source: FilterAdvancedField): FilterClient {
  const target = {
    gender: [],
    domiciles: [],
    ageRange: {
      from: 0,
      to: 100
    },
  } as FilterClient;
  (source?.children || [])
    .forEach((child: FilterAdvancedField) => {
      switch (child.name) {
        case fnaClientGender:
          target.gender = child.valueList;
          break;
        case fnaClientDomicile:
          target.domiciles = child.valueList;
          break;
        case fnaClientAge:
          (child.type === 'composite' && child.operator === 'each' ? child.children : [child])
          .forEach(c => {
            if (c.operator === 'gte') {
              target.ageRange.from = c.valueNum;
            }
            if (c.operator === 'lte') {
              target.ageRange.to = c.valueNum;
            }
          });
          break;
      }
    });
  return target;
}

function filterBodyFieldsToIntermediaries(source: FilterAdvancedField): FilterIntermediaries {
  const children = (source?.children || []);
  const eamField = children.find(f => f.type === fnaExcludeEAM);
  const ewaField = children.find(f => f.type === fnaExcludeEWA);
  return {
    excludeEAM: (eamField?.valueNum ?? 0) !== 0,
    excludeEWA: (ewaField?.valueNum ?? 0) !== 0,
  } as FilterIntermediaries;
}

function filterBodyFieldsToPortfolio(source: FilterAdvancedField): FilterPortfolio {
  const target = {
    riskRange: {},
    returnRange: {},
    valueRange: {},
    liquidityRange: {},
    allowOptOut: false,
  } as FilterPortfolio;
  (source?.children || [])
    .forEach(child => {
      switch (child.name) {
        case fnaPortfolioType:
          target.portfolioType = child.valueList;
          break;
        case fnaPortfolioRiskProfile:
          target.model = child.valueList;
          break;
        case fnaPortfolioReferenceCurrency:
          target.referenceCurrency = child.valueList;
          break;
        case fnaPortfolioBusinessUnit:
          target.bu = child.valueList;
          break;
        case fnaPortfolioServiceCenter:
          target.serviceCenter = child.valueList;
          break;
        case fnaPortfolioBusinessDivision:
          target.businessDivision = child.valueList;
          break;
        case fnaPortfolioAdvisoryType:
          target.advisoryType = child.valueList;
          break;
        case fnaPositionSustainabilityRating:
          target.sustainabilityRating = child.valueList;
          break;
        case fnaPortfolioSustainabilityProfile:
          target.sustainabilityProfile = child.valueList;
          break;
        case fnaPortfolioMifid:
          target.mifid = child.valueList;
          break;
        case fnaPortfolioFidleg:
          target.fidleg = child.valueList;
          break;
        case fnaPortfolioRmDepartment:
          target.rmDepartment = {key: child.valueList[0], value: child.valueList[0]};
          break;
        case fnaPortfolioRmMarket:
          target.rmMarket = child.valueList;
          break;
        case fnaPortfolioRmLocation:
          target.rmLocation = child.valueList;
          break;
        case fnaPortfolioManager:
          target.manager = child.valueList;
          break;
        case fnaPortfolioAdvisor:
          target.advisor = child.valueList;
          break;
        case fnaPortfolioRisk:
          if (!target.riskRange) {
            target.riskRange = {};
          }
          if (child.operator === 'gte') {
            target.riskRange.min = child.valueNum;
          }
          if (child.operator === 'lte') {
            target.riskRange.max = child.valueNum;
          }
          break;
        case fnaPortfolioReturn:
          if (!target.returnRange) {
            target.returnRange = {};
          }
          if (child.operator === 'gte') {
            target.returnRange.min = child.valueNum;
          }
          if (child.operator === 'lte') {
            target.returnRange.max = child.valueNum;
          }
          break;
        case fnaPortfolioValue:
          if (!target.valueRange) {
            target.valueRange = {};
          }
          if (child.operator === 'gte') {
            target.valueRange.min = child.valueNum;
          }
          if (child.operator === 'lte') {
            target.valueRange.max = child.valueNum;
          }
          break;
        case fnaPortfolioLiquidity:
          if (!target.liquidityRange) {
            target.liquidityRange = {};
          }
          if (child.operator === 'gte') {
            target.liquidityRange.min = child.valueNum;
          }
          if (child.operator === 'lte') {
            target.liquidityRange.max = child.valueNum;
          }
          break;
        case fnaPortfolioWaiveOfAdvice:
          target.waiveOfAdvice = !!child.valueNum;
          break;
        case fnaPortfolioQualifiedInvestor:
          target.qualifiedInvestor = !!child.valueNum;
          break;
        case fnaPortfolioRiskState:
          if (child.valueStr === 'within' || child?.valueList?.join(',') === 'within') {
            target.riskBreachOnly = child.operator === 'ne';
            target.excludeRiskBreach = child.operator === 'eq';
          }
          break;
        case fnaPortfolioAllowOptOut:
          target.allowOptOut = true;
          break;
      }
    });
  return target;
}

function filterBodyFieldsToPosition(source: FilterAdvancedField): FilterPosition {
  const target = {
    ids: [],
    excludedIds: [],
    assetType: [],
    assetClass: [],
    assetSubClass: [],
    ratingMoody: [],
    ratingSP: [],
    sustainabilityRating: [],
    productRatingApac: [],
    productRatingMe: [],
    productInvestmentHorizon: [],
    issuerName: undefined,
    ratingSourceLGT: [],
    ranking: [],
    nextCallDate: {
      from: undefined,
      to: undefined,
    },
    maturityDate: {
      from: undefined,
      to: undefined,
    },
    value: {
      min: undefined,
      max: undefined,
    }
  } as FilterPosition;
  (source?.children || [])
    .forEach(child => {
      switch (child.name) {
        case fnaPositionPerformance:
          if (!target.value) {
            target.value = {};
          }
          if (!target.value.min) target.value.min = 0;
          if (!target.value.max) target.value.max = 1;
          (child.type === 'composite' && child.operator === 'each' ? child.children : [child])
          .forEach(c => {
            if (c.operator === 'gte') {
              target.value.min = c.valueNum / 100;
            }
            if (c.operator === 'lte') {
              target.value.max = c.valueNum / 100;
            }
          });
          break;
        case fnaPositionType:
          target.assetType = child.valueList;
          break;
        case fnaPositionClass:
          target.assetClass = child.valueList;
          break;
        case fnaPositionSubClass:
          target.assetSubClass = child.valueList;
          break;
        case fnaPositionMoodyRating:
          target.ratingMoody = child.valueList;
          break;
        case fnaPositionRatingSP:
          target.ratingSP = child.valueList;
          break;
        case fnaPositionSustainabilityRating:
          target.sustainabilityRating = child.valueList;
          break;
        case fnaPositionProductRatingApac:
          target.productRatingApac = child.valueList;
          break;
        case fnaPositionProductRatingMe:
          target.productRatingMe = child.valueList;
          break;
        case fnaPositionProductInvestmentHorizon:
          target.productInvestmentHorizon = child.valueList;
          break;
        case fnaPositionIssuerName:
          target.issuerName = {key: child.valueList[0], value: child.valueList[0]};
          break;
        case fnaPositionRanking:
          target.ranking = child.valueList;
          break;
        case fnaPositionRatingSourceLGT:
          target.ratingSourceLGT = child.valueList;
          break;
        case fnaPositionIds:
          if (child.operator === 'eq') {
            target.ids = child.valueList;
          }
          if (child.operator === 'ne') {
            target.excludedIds = child.valueList;
          }
          break;
        case fnaPositionNextCall:
          if (!target.nextCallDate) {
            target.nextCallDate = {};
          }
          (child.type === 'composite' && child.operator === 'each' ? child.children : [child])
          .forEach(c => {
            if (c.operator === 'gte') {
              target.nextCallDate.from = c.valueStr;
            }
            if (c.operator === 'lte') {
              target.nextCallDate.to = c.valueStr;
            }
          });
          break;
        case fnaPositionMaturity:
          if (!target.maturityDate) {
            target.nextCallDate = {};
          }
          (child.type === 'composite' && child.operator === 'each' ? child.children : [child])
          .forEach(c => {
            if (c.operator === 'gte') {
              target.maturityDate.from = c.valueStr;
            }
            if (c.operator === 'lte') {
              target.maturityDate.to = c.valueStr;
            }
          });
          break;
      }
    });
  return target;
}

function filterBodyFieldsToAssetsInclude(source: FilterAdvancedField): CompositeList<AssetRange> {
  const assetsField = (source?.children || [])
    .find(child => child.name === EFilterCategories.assets && child.valueStr != 'exclude');
  return {
    // cannot directly use field value (string) because compiler expects an Atomics method type
    operator: assetsField?.operator === 'and' ? 'and' : 'mor',
    children: getAssetsFieldChildren(assetsField)
  };
}
function filterBodyFieldsToAssetsExclude(source: FilterAdvancedField): CompositeList<AssetRange> {
  const assetsField = (source?.children || [])
    .find(child => child.name === EFilterCategories.assets && child.valueStr == 'exclude');
  return {
    // cannot directly use field value (string) because compiler expects an Atomics method type
    operator: assetsField?.operator === 'and' ? 'and' : 'mor',
    children: getAssetsFieldChildren(assetsField)
  };
}
function getUnbundledType(name: string): UnbundledType {
  return name.startsWith('unbundled.') ? 'unbundled' : name.startsWith('underlying') ? 'underlying' :  'direct'
}
function getAssetsFieldChildren(assetsField: FilterAdvancedField): AssetRange[] {
  const assertRangeForEach = (parent: FilterAdvancedField) => {
    const child0 = parent.children[0];
    const name = child0.name;
    const assetRange: AssetRange = {
      isValue: name.endsWith(fnaPositionIsinTotalValue),
      key: child0.field,
      name: child0.valueStr,
      range: {},
      hasExposure: false,
      unbundledType: getUnbundledType(name),
    };
    parent.children.forEach(child => {
      if (child.operator === 'gte') {
        assetRange.range.min = child.valueNum;
      }
      if (child.operator === 'lte') {
        assetRange.range.max = child.valueNum;
      }
    });
    assetRange.hasExposure = assetRange.range.min == null && assetRange.range.max == null;
    assetRange.hasPerformance = name == fnaPositionProfitAndLoss;
    return assetRange;
  }
  const assetsForEach = (parent: FilterAdvancedField) => {
    if (parent.name?.endsWith(fnaPositionIsin)) {
      return {
        isValue: false,
        key: parent.field,
        name: parent.valueStr,
        range: {},
        hasExposure: true,
        unbundledType: getUnbundledType(parent.name)
      } as AssetRange;
    } else {
      return assertRangeForEach(parent);
    }
  }
  return (assetsField?.children || [])
    .map(parent => assetsForEach(parent));
}

function firstValueListOrValueStr(child: FilterAdvancedField): string {
  let result: string;
  if (child) {
    if (child.valueList && child.valueList.length > 0) {
      result = child.valueList[0];
    } else {
      result = child.valueStr;
    }
  }
  return result;
}

type WeightKey = {
  key: string;
  w: WeightRange;
};
type WeightField = {
  key: string;
  fields: FilterAdvancedField[];
}

function filterBodyToWeightRange(
  source: FilterAdvancedField,
  category: string,
  weightIdent: string,
  weightValue: string
): CompositeList<WeightRange> {
  const items = []
  const field = (source?.children || [])
    .find(child => child.name === category);
  (field?.children || [])
    .forEach(parent => {
      items.push(filterBodyToWeightRangeForWeight(weightIdent, weightValue, parent) ||
        filterBodyToWeightRangeForRange(weightIdent, weightValue, parent));
    });
  return {
    // cannot directly use field value (string) because compiler expects an Atomics method type
    operator: field?.operator === 'and' ? 'and' : 'mor',
    children: items
  };
}

function filterBodyToWeightRangeIsUnbundled(name: string) {
  return name.startsWith('unbundled.');
}

function filterBodyToWeightRangeIsName(name: string, expected: string) {
  return name == expected || name == `unbundled.${expected}`;
}

function filterBodyToWeightRangeForWeight(weightIdent: string, weightValue: string, parent: FilterAdvancedField) {
  if (parent.name != weightIdent && parent.name != `unbundled.${weightIdent}`) return;
  const key = [parent.name, parent.field].join(':');
  if (!key) return;
  return {
    key: parent.field,
    weight: firstValueListOrValueStr(parent) as WeightEnum,
    range: {
      min: undefined,
      max: undefined,
    },
    unbundledType: filterBodyToWeightRangeIsUnbundled(parent.name) ? 'unbundled' : 'direct'
  };
}

function filterBodyToWeightRangeForRange(weightIdent: string, weightValue: string, parent: FilterAdvancedField) {
  if (!parent.children?.length) return;
  let key: string;
  let item: WeightRange
  //parent has 2 children, one for min and one for max, which should be processed together and return one item
  parent.children.forEach(child => {
    key = [child.name, child.field].join(':');
    item = item || {
      key: child.field,
      // TODO: Only kept for compatibility reasons. Weight should be undefined here, because mutually exclusive with range
      weight: child.name === weightIdent ? firstValueListOrValueStr(child) as WeightEnum: undefined,
      range: {
        min: undefined,
        max: undefined,
      },
      unbundledType: filterBodyToWeightRangeIsUnbundled(child.name) ? 'unbundled' : 'direct'
    };
    if (filterBodyToWeightRangeIsName(child.name, weightValue) && child.operator === 'gte') {
      item.range.min = child.valueNum;
    }
    if (filterBodyToWeightRangeIsName(child.name, weightValue) && child.operator === 'lte') {
      item.range.max = child.valueNum;
    }
  });
  return item;
}

function filterBodyFieldsToAssetClass(source: FilterAdvancedField): CompositeList<WeightRange> {
  return filterBodyToWeightRange(source, EFilterCategories.assetClass, fnaAssetClassWeight, fnaAssetClassValue);
}

function filterBodyFieldsToCurrency(source: FilterAdvancedField): CompositeList<WeightRange> {
  return filterBodyToWeightRange(source, EFilterCategories.currency, fnaCurrencyWeight, fnaCurrencyValue);
}

function filterBodyFieldsToGics(source: FilterAdvancedField): CompositeList<WeightRange> {
  return filterBodyToWeightRange(source, EFilterCategories.sector, fnaSectorWeight, fnaSectorValue);
}

function filterBodyFieldsToRegion(source: FilterAdvancedField): CompositeList<WeightRange> {
  return filterBodyToWeightRange(source, EFilterCategories.region, fnaRegionWeight, fnaRegionValue);
}

function filterBodyFieldsToOrgPosition(source: FilterAdvancedField): FilterOrgPosition {
  const children = (source?.children || []).filter(c => c.type === 'orgPosition');
  return {
    positions: (children.length) ? (children[0].valueList || []) : [],
  };
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {genBooleanColumn, genCodeTableColumn, genTextColumn} from '../../../util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../services/global.service';
import {CodeTableEntry, LicenseService, User, UserRoleType, UserService} from '../../../api/core';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {GridResetEvent} from "../../../shared/grid/grid.component";
import {Tab} from "../../../models/tabs.model";
import {SETTINGS_TABS} from "../../../util/tab.constants";
import {CodeTableService} from "../../../services/code-table.service";
import {ECodeTables} from "../../../util/enum";
import {DataService} from "../../../services/data.service";
import {TabChangedArg} from "./settings-tab-picker/settings-tab-picker.component";

const DEFAULT_TAB = 0;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent {

  tabs: Tab[] = SETTINGS_TABS;
  defaultTab: string = this.tabs[DEFAULT_TAB].text;
  activeTab: string;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly dataService: DataService,
  ) { }



  setActiveTab({category, tab}: TabChangedArg) {
    if (this.activeTab === tab) {
      return;
    }
    this.dataService.updateMainTitle(this.translateService.instant(category+'Settings'));
    setTimeout(() => {
      this.dataService.updateTitleExtension(this.translateService.instant(tab));
    })
    this.activeTab = tab;
  }

}

/**
 * Hash code for a string
 * @param str
 */
export function hashCode(str: string): number {
  return Array.from(str)
    .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
}

import {Component, ViewChild} from "@angular/core";
import {ColDef, GridOptions} from "ag-grid-community";
import {genCodeTableColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {TranslateService} from "@ngx-translate/core";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {
  CodeTableEntry,
  Disclaimer,
  DisclaimerAssignment, DisclaimerContent, DisclaimerEdit,
  DisclaimerFallback,
  DisclaimerService
} from "../../../../api/core";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../../../services/notification.service";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {GridComponent} from "../../../../shared/grid/grid.component";
import {CodeTableService} from "../../../../services/code-table.service";
import {ECodeTables, EModalType} from "../../../../util/enum";
import {MatSelectChange} from "@angular/material/select";
import {DialogHeight, DialogWidth, ModalService} from "../../../../services/modal.service";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {ModalData} from "../../../../models/modal.model";
import {DisclaimerAssignmentDialogComponent} from "./assignment-dialog/disclaimer-assignment-dialog.component";
import {DisclaimerDialogComponent} from "./disclaimer-dialog/disclaimer-dialog.component";

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent {
  @ViewChild("disclaimer_grid")
  disclaimerGrid: GridComponent;

  @ViewChild("assignment_grid")
  assignmentGrid: GridComponent;

  columnDefs: ColDef[] = [
    genTextColumn(
      'name',
      this.translateService.instant('name')
    ),
    {
      ...genTextColumn(
        'content',
        this.translateService.instant('language'),
        (params: any) => params.data.contents?.map(c => c.language.ident)?.join(', ') ?? ''
      ),
      floatingFilter: false,
      sortable: false,
    },
    genIconButtonColumn({
      callback: (data: Disclaimer) => this.onUpdate(data),
      icon: 'edit_m',
      tooltip: this.translateService.instant('edit'),
    }),
    genIconButtonColumn({
      callback: (data: Disclaimer) => this.onDelete(data),
      icon: 'delete',
      tooltip: this.translateService.instant('delete'),
    }),
  ];
  assignmentsColumnDefs: ColDef[] = [
    {
      ...genCodeTableColumn({
        field: 'hub',
        headerName: this.translateService.instant('hub'),
        observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      }),
    },
    {
      ...genCodeTableColumn({
        field: 'publicationType',
        headerName: this.translateService.instant('publicationType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.publicationType),
      }),
      flex: 1,
    },
    {
      ...genTextColumn(
        'disclaimer.name',
        this.translateService.instant('disclaimer')
      ),
      flex: 1,
    },
    genIconButtonColumn({
      callback: (data: DisclaimerAssignment) => this.onEditAssignment(data),
      icon: 'edit_m',
      tooltip: this.translateService.instant('edit'),
    }),
    genIconButtonColumn({
      callback: (data: DisclaimerAssignment) => this.onDeleteAssignment(data),
      icon: 'delete',
      tooltip: this.translateService.instant('delete'),
    }),
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };

  data: GridDataProvider;
  assignmentData: GridDataProvider;
  languages: CodeTableEntry[] = [];
  fallbacks: DisclaimerFallback[];

  constructor(
    readonly translateService: TranslateService,
    readonly disclaimerService: DisclaimerService,
    readonly fb: FormBuilder,
    readonly notificationService: NotificationService,
    readonly codeTableService: CodeTableService,
    readonly modalService: ModalService,
  ) {
    this.catchError = this.catchError.bind(this);
    this.data = this.disclaimerService.getDisclaimers.bind(disclaimerService);
    this.assignmentData = this.disclaimerService.getDisclaimerAssignments.bind(disclaimerService);
    this.catChError = this.catChError.bind(this);
    this.codeTableService.getCodeTable(ECodeTables.language).subscribe(languages => {
      this.languages = languages;
    });
    this.disclaimerService.getDisclaimerFallbacks().subscribe(fallbacks => {
      this.fallbacks = fallbacks;
    });
  }

  onAddDisclaimer() {
    this.onUpdateDialog({
      name: '',
      contents: []
    }, 'disclaimerAddTitle', 'create', result => {
      this.disclaimerService.createDisclaimer(result)
        .pipe(
          catchError(this.catChError)
        )
        .subscribe((value) => {
          if (value) {
            this.notificationService.handleSuccess(this.translateService.instant('disclaimerSaved'));
            this.disclaimerGrid.reload();
          }
        });
    });
  }

  onUpdate(data: Disclaimer) {
    this.disclaimerService.getDisclaimer(data.id!!).subscribe(disclaimer => {
      this.onUpdateDialog(disclaimer, 'disclaimerEditTitle', 'save', result => {
        this.disclaimerService.editDisclaimer(data.id!!, result)
          .pipe(
            catchError(this.catChError)
          )
          .subscribe((value) => {
            if (value) {
              this.notificationService.handleSuccess(this.translateService.instant('disclaimerSaved'));
              this.disclaimerGrid.reload();
            }
          });
      });
    })
  }

  onUpdateDialog(disclaimer: Disclaimer, title: string, ok: string, cb: (disclaimer: DisclaimerEdit) => void) {
    const modalData: ModalData = {
      type: EModalType.custom,
      title: this.translateService.instant(title),
      data: {
        disclaimer,
        languages: this.languages,
      },
      submitBtn: {
        label: this.translateService.instant(ok),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: DisclaimerDialogComponent,
    }
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.HALF,
      DialogHeight.AUTO
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      cb(result as DisclaimerEdit);
    });
  }

  onDelete(data: Disclaimer) {
    this.modalService.openConfirmationDialog({
      type: EModalType.confirmationDialog,
      title: this.translateService.instant('disclaimerDeleteTitle'),
      data: {
        message: this.translateService.instant('disclaimerDeleteMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('yes'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.disclaimerService.deleteDisclaimer(data.id)
            .pipe(
              catchError(this.catChError)
            )
            .subscribe(value => {
              modalRef.close();
              this.disclaimerGrid.reload();
              this.assignmentGrid.reload();
              if (typeof value == 'boolean') return;
              this.notificationService.handleSuccess(this.translateService.instant('disclaimerDeleted'));
            });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('no'),
      },
    });
  }

  private catChError(err, caught) {
    const errorMessage = err?.error?.error;
    this.notificationService.handleError(this.translateService.instant(errorMessage));
    return of(false);
  }

  onAddAssignment() {
    const modalData: ModalData = {
      type: EModalType.custom,
      title: this.translateService.instant('disclaimerAssignmentAdd'),
      data: {},
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: DisclaimerAssignmentDialogComponent,
    }
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.AUTO,
      DialogHeight.AUTO);
    dialogRef.afterClosed().subscribe((result?: DisclaimerAssignment) => {
      if (!result || typeof result == 'boolean') return;
      this.disclaimerService.saveDisclaimerAssignment(result)
        .pipe(
          catchError(this.catchError)
        )
        .subscribe(res => {
          if (typeof res == 'boolean') return;
          this.assignmentGrid.reload();
          this.notificationService.handleSuccess(this.translateService.instant('disclaimerAssignmentCreated'));
        })
    })
  }

  onEditAssignment(data: DisclaimerAssignment) {
    const modalData: ModalData = {
      type: EModalType.custom,
      title: this.translateService.instant('disclaimerAssignmentEdit'),
      data: {
        assignment: data
      },
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: DisclaimerAssignmentDialogComponent,
    }
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.AUTO,
      DialogHeight.AUTO);
    dialogRef.afterClosed().subscribe((result?: DisclaimerAssignment) => {
      if (!result || typeof result == 'boolean') return;
      this.disclaimerService.saveDisclaimerAssignment(result)
        .pipe(
          catchError(this.catchError)
        )
        .subscribe(res => {
          if (typeof res == 'boolean') return;
          this.assignmentGrid.reload();
          this.notificationService.handleSuccess(this.translateService.instant('disclaimerAssignmentSaved'));
        })
    })
  }

  onDeleteAssignment(data: DisclaimerAssignment) {
    this.modalService.openConfirmationDialog({
      type: EModalType.confirmationDialog,
      title: this.translateService.instant('disclaimerAssignmentDeleteTitle'),
      data: {
        message: this.translateService.instant('disclaimerAssignmentDeleteMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('yes'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.disclaimerService.deleteDisclaimerAssignment(
            data.id
          )
            .pipe(
              catchError(this.catchError)
            )
            .subscribe(value => {
              modalRef.close();
              if (typeof value == 'boolean') return;
              this.assignmentGrid.reload();
              this.notificationService.handleSuccess(this.translateService.instant('disclaimerAssignmentDeleted'));
            });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('no'),
      },
    });
  }

  private catchError(err, caught) {
    const errorMessage = err?.error?.error;
    this.notificationService.handleError(this.translateService.instant(errorMessage));
    return of(false);
  }

  onFallbackChanged(changes: DisclaimerFallback) {
    this.disclaimerService.setDisclaimerFallbacks({
      hub: changes.hub.ident,
      language: changes.language.ident,
    }).subscribe(fallbacks => {
      this.fallbacks = fallbacks;
      this.notificationService.handleSuccess(this.translateService.instant('disclaimerFallbackSaved'))
    });
  }
}

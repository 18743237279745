<div class="colored-menu-trigger"
     [matMenuTriggerFor]="tabMenu"
>
  <app-config-icon
    [iconIdentifier]="activeTabIcon"
    [matTooltip]="'buckets' | translate"
  ></app-config-icon>
  <div>{{ (activeTab || '') | translate }}</div>
  <app-config-icon iconIdentifier="expand_more"></app-config-icon>
  <mat-menu #tabMenu="matMenu" class="settings-menu">
    <div class="app-settings-tab-picker-popup-container" *ngIf="categories && categories.length > 0">
      <div class="app-settings-tab-picker-popup-column" *ngFor="let cat of categories">
        <div class="popup-title">{{ cat.name + 'Settings' | translate}}</div>
        <button
          mat-menu-item
          *ngFor="let tab of cat.tabs"
          class="mat-menu-item-flex"
          (click)="onTabClicked(tab.text)"
        >
          <app-config-icon [iconIdentifier]="tab.icon"></app-config-icon>
          <span>{{ tab.text | translate }}</span>
        </button>
      </div>

    </div>
  </mat-menu>
</div>

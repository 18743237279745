import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Campaign, Portfolio, PortfolioPosition, PortfolioService, User} from 'src/app/api/core';
import {usernameValueLabel} from 'src/app/util/grid/grid-renderer.util';
import {GlobalService} from "../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {GridService} from "../../services/grid.service";
import {ModalComponent} from "../modal/modal.component";
import {LabelBuilder} from "../../util/label-builder";
import {ColDef, GridOptions} from "ag-grid-community";
import {DataService} from "../../services/data.service";
import {filter, Subscription} from "rxjs";
import {PermissionService} from "../../services/permission.service";

/**
 * Used in Modal in Campaign Portfolio List
 */
@Component({
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
})
export class PortfolioDetailsComponent implements OnInit, OnDestroy{
  campaign?: Campaign;
  portfolio: Portfolio;

  columnDefs: ColDef[] = [];
  autoGroupColumnDef: ColDef;
  gridOptions: GridOptions;
  data: PortfolioPosition[] = [];
  private subscriptions: Subscription[] = [];
  userHubs: string[] = [];

  constructor(
    protected readonly globalService: GlobalService,
    protected readonly translateService: TranslateService,
    protected readonly portfolioService: PortfolioService,
    protected readonly labelBuilder: LabelBuilder,
    protected readonly gridService: GridService,
    protected readonly dataService: DataService,
    protected readonly permissionService: PermissionService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { data: Portfolio }
  ) {
    this.autoGroupColumnDef =
      this.gridService.getPortfolioPositionAutoGroupColumnDef(this);
    this.portfolio = data.data;
    this.gridOptions = {
      rowHeight: 36,
      suppressContextMenu: true,
      suppressCellFocus: true,
      groupDefaultExpanded: 1,
      suppressAggFuncInHeader: true,
      paginationAutoPageSize: true,
    };
  }

  usernameLabel(u: User) {
    return usernameValueLabel(u);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
        }),
    );
    if (this.portfolio.id != null) {
      // portfolio-positions are going to be fetched and a new calculated field is added to the data
      // - isInCampaign: boolean - if the asset is in the campaign buy products
      this.portfolioService.getPortfolioPositions(this.portfolio.id).subscribe({
        next: (result) => {
          const buyProducts = this.campaign ? this.campaign.buyProducts : [];
          this.data = result.map(d => {
            return {...d, isInCampaign: buyProducts.some(b => b.isin === d.assetIsin)}
          });
        },
      });
    }
    this.subscriptions.push(
      this.permissionService.user$.subscribe((user) => {
        this.userHubs = user.hubs.map((h) => h.ident);
        this.columnDefs = this.gridService.getPortfolioPositionColDef(this, this.userHubs);
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

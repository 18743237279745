import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Tab} from "../../../../models/tabs.model";
import {ActivatedRoute} from "@angular/router";
import {PermissionService} from "../../../../services/permission.service";

type CategoryDefinition = {
  name: string;
  children: string[];
}

type Category = {
  name: string;
  tabs: Tab[];
}

const categoryDefinitions: CategoryDefinition[] = [
  {
    name: 'configuration',
    children: [
      'salutations',
      'templateDefaults',
      'buckets',
      'filterConfig',
      'ccFramework',
      'fieldDefinitions',
      'contentDefinitions',
      'disclaimer',
      'disclaimerAssignment',
    ],
  },
  {
    name: 'monitoring',
    children: [
      'users',
      'outgoingMessages',
      'sentMessages',
      'monitoringOutgoingMessages',
      'systemStatus',
      'reportLog',
    ],
  },
  {
    name: 'utils',
    children: [
      'appMessages',
      'reportRequestCreator',
      'reportRequest',
    ],
  },
];

export type TabChangedArg = {
  category: string;
  tab: string;
}

@Component({
  selector: 'app-settings-tab-picker',
  templateUrl: './settings-tab-picker.component.html'
})
export class SettingsTabPickerComponent {
  _tabs: Tab[];
  @Input() defaultTab: string;
  @Input() tabChangeAllowed = true;
  @Output() tabChanged = new EventEmitter<TabChangedArg>();

  @Input() activeTab: string;

  @Input() set tabs(tabs: Tab[]) {
    this._tabs = tabs;
    const categories = categoryDefinitions.map(def => ({
      name: def.name,
      tabs: def.children.map(t => tabs.find(tab => tab.text === t)).filter(Boolean)
    }));
    const categoryTabs: string[] = categoryDefinitions.reduce((prev, curr) => [...prev, ...curr.children] , []);
    const otherTabs = tabs.filter(tab => !categoryTabs.includes(tab.text));
    if (otherTabs.length) {
      categories.push({
        name: 'other',
        tabs: otherTabs
      });
    }
    this.categories = categories;
    if (tabs) {
      this.activatedRoute.data.subscribe((data) => {
        const tab = tabs.find((tab) => tab.routerLink === data.viewRoute && !tab.hidden);
        if (tab) {
          setTimeout(() => {
            this.onTabClicked(tab.text, true);
          });
        }
      });
    }
  }

  categories: Category[];

  get tabs() {
    return this._tabs;
  }

  get activeTabIcon() {
    return this._tabs.find(t => t.text === this.activeTab)?.icon
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
  ) {
  }

  ngOnInit(): void {
    this.activeTab = this.defaultTab;
    setTimeout(() => this.onTabClicked(this.activeTab, true));
  }

  ngOnDestroy(): void {
  }

  onTabClicked(tab: string, force: boolean = false): void {
    if (force || this.tabChangeAllowed) {
      this.activeTab = tab;
      const category = this.categories.find(cat =>  cat.tabs.find(t => t.text === tab))
      this.tabChanged.emit({category: category.name, tab});
    }
  }
}

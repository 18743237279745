import {AfterViewInit, Component, Inject} from "@angular/core";
import {AppMessage, CodeTableEntry, UserRoleType} from "../../../../api/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {codeTableEntries} from "../../../../services/code-table.service";
import {EModalType} from "../../../../util/enum";
import {ModalSubComponent} from "../../../../models/modal.model";
import {MAX_DATE, MIN_DATE} from "../../../../util/date-formatter";
import {FroalaEditorService} from "../../../../services/froala-editor.service";
import {DatePipe} from "@angular/common";


@Component({
  selector: 'app-message-edit-dialog',
  templateUrl: './app-message-edit-dialog.component.html'
})
export class AppMessageEditDialogComponent implements AfterViewInit, ModalSubComponent {
  readonly message: AppMessage;
  readonly title: string;
  readonly isCreating: boolean;
  readonly maxDate = MAX_DATE;
  readonly minDate = MIN_DATE;
  selectAll = true;
  hubs: CodeTableEntry[];
  roles: UserRoleType[];
  _selectedRoles: UserRoleType[] = [];
  formGroup: FormGroup;
  editorOptions: any;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly toastService: ToastrService,
    protected readonly froalaEditorService: FroalaEditorService,
    protected readonly fb: FormBuilder,
    protected readonly datePipe: DatePipe,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
      message: AppMessage;
        hubs: CodeTableEntry[];
        roles: UserRoleType[];
    } }
  ) {
    this.editorOptions = {... this.froalaEditorService.editorOptions };
    const message = data.data.message;
    this.message = message;
    this.hubs = data.data.hubs;
    this.roles = data.data.roles;
    this.isCreating = !this.message.id;
    this.formGroup = this.fb.group({
      title: new FormControl(
        message.title,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(500)
        ]
      ),
      hub: new FormControl(
        this.hubs.find(b => b.id == message.hub?.id),
      ),
      validFrom: new FormControl(
        message.validFrom,
        Validators.required,
      ),
      validTo: new FormControl(
        message.validTo,
        Validators.required,
      ),
      content: new FormControl(
        message.content,
        [Validators.required, Validators.maxLength(10000)],
      ),
    });
    if (message.id) {
      this.selectedRoles = message.roles.map(
        r => this.roles.find(p => p == r)
      );
      this.selectAll = this.selectedRoles.length === this.roles.length;
    } else {
      this.selectAll = true;
      this.selectedRoles = [...this.roles];
    }
    this.formGroup.valueChanges.subscribe(() => this.updateSaveButtonVisibility());
    this.updateCodeTableEntries();
  }

  get selectedRoles(): UserRoleType[] {
    return this._selectedRoles;
  }
  set selectedRoles(values: UserRoleType[]) {
    this._selectedRoles = values;
  }

  ngAfterViewInit() {
    this.updateSaveButtonVisibility();
  }

  modalAction(modalType: EModalType | undefined) {
    const message = this.getMessageFromForm();
    const err = this.checkMessage(message);
    if (err) {
      this.toastService.info(
        this.translateService.instant(err)
      );
      return;
    }
    this.dialogRef.close(message);
  }

  private updateSaveButtonVisibility() {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.formGroup.invalid || this.selectedRoles.length === 0;
  }

  private updateCodeTableEntries() {
    this.hubs = codeTableEntries(this.hubs, this.formGroup.value.hub);
  }

  private getMessageFromForm(): AppMessage {
    const title: string = this.formGroup.get('title').value;
    const content: string = this.formGroup.get('content').value;
    const hub: CodeTableEntry = this.formGroup.get('hub').value;
    const roles: UserRoleType[] = [...this.selectedRoles];
    const validFrom: string = this.datePipe.transform(this.formGroup.get('validFrom').value, 'yyyy-MM-dd');
    const validTo: string = this.datePipe.transform(this.formGroup.get('validTo').value, 'yyyy-MM-dd');
    return {
      id: this.message.id,
      title,
      hub,
      content,
      roles,
      validFrom,
      validTo,
      readerCount: 0,
    };
  }

  private checkMessage(message: AppMessage): string {
    if (!message.title) return 'appMessagesTitleRequired';
    if (!message.validFrom) return 'appMessagesValidFromRequired';
    if (!message.validTo) return 'appMessagesValidToRequired';
    return '';
  }

  selectRole(role: UserRoleType) {
    const selectedRoles = this.selectedRoles;
    const isSelecting = selectedRoles.indexOf(role) == -1;
    this._selectedRoles = (isSelecting ? [...selectedRoles, role] : selectedRoles.filter(r => r != role));
    this.selectAll = this._selectedRoles.length === this.roles.length;
    this.updateSaveButtonVisibility();
  }

  onSelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll === true) {
      this._selectedRoles = [...this.roles];
    } else {
      this._selectedRoles.splice(0, this._selectedRoles.length);
    }
    this.updateSaveButtonVisibility();
  }
}

<app-tabs-picker-toolbar>
  <button mat-stroked-button (click)="onRefresh()">
    <app-config-icon iconIdentifier="refresh"></app-config-icon>
    {{ 'refresh' | translate }}
  </button>
</app-tabs-picker-toolbar>
<app-grid
  tableId="report-log-list"
  *ngIf="!download"
  [columnDefs]="colDefs"
  [data]="data"
  [gridOptions]="gridOptions"
></app-grid>
<div class="download" *ngIf="download">
  <div class="header">{{'reportLog' | translate}}: {{ download.ident }}</div>
  <div class="status">{{download.current}} / {{download.total}}</div>
  <mat-progress-bar mode="determinate" [value]="download.progress"></mat-progress-bar>
  <div class="result">
    <button mat-raised-button
            [color]="download.ready ? 'primary' : undefined"
            [disabled]="!download.ready"
            (click)="onDownloadResult()" >
      {{'download' | translate}}
    </button>
  </div>
</div>

import {Component, OnDestroy, OnInit} from "@angular/core";
import {DistributorPortfolioClient, Portfolio, PortfolioPosition, PortfolioService} from "../../../../api/core";
import {GridService} from "../../../../services/grid.service";
import {ActivatedRoute} from "@angular/router";
import {GlobalService} from "../../../../services/global.service";
import {NotificationService} from "../../../../services/notification.service";
import {ConfigService} from "../../../../services/config.service";
import {TranslateService} from "@ngx-translate/core";
import {genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {finalize, first, Observable, of, Subscription} from "rxjs";
import {EProtectedActions} from "../../../../util/protected-actions";
import {LabelBuilder} from "../../../../util/label-builder";
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {I18n} from "../../../../services/i18n.service";
import {EViewRoutes} from "../../../../util/enum";
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {PermissionService} from "../../../../services/permission.service";

@Component({
  selector: 'app-distributor-portfolio-overview',
  templateUrl: './distributor-portfolio-overview.component.html'
})
export class DistributorPortfolioOverviewComponent implements OnInit, OnDestroy{
  portfolio: Portfolio;
  loadingOptOut$: Observable<boolean>;
  dataPositions: PortfolioPosition[] = [];
  autoGroupColumnDef: ColDef =
    this.gridService.getPortfolioPositionAutoGroupColumnDef(this);
  portfolioPositionDefs: ColDef[] = [];
  positionGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    groupDefaultExpanded: 1,
    suppressAggFuncInHeader: true,
  };
  clientsColumnDefs: ColDef[] = [
    genLinkColumn({
      field: "personNumber",
      headerName: I18n.getColName('personNumber'),
      link: (data: any) => `${EViewRoutes.distributorClientOverview}/${data.id}`,
    }),
    genTextColumn(
      "fullName",
      I18n.getColName('name'),
      null,
      {customPath: 'clientName'}
    ),
    {
      ...genTextColumn(
        "publicationTypes",
        I18n.getColName('directDelivery'),
      ),
      tooltipField: 'publicationTypes',
    },
  ];
  clientsGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<DistributorPortfolioClient>) => {
      this.gridApi = event.api;
      this.subscriptions.push(
        I18n.getColumns(this.translateService, event.api)
      );
    }
  };

  rows: DistributorPortfolioClient[] = []
  gridApi: GridApi;
  subscriptions: Subscription[] = [];
  userHubs: string[] = [];

  constructor(
    protected readonly gridService: GridService,
    protected readonly portfolioService: PortfolioService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly globalService: GlobalService,
    protected readonly notificationService: NotificationService,
    protected readonly configService: ConfigService,
    protected readonly translateService: TranslateService,
    protected readonly permissionService: PermissionService,
    protected readonly labelBuilder: LabelBuilder
  ) {
    activatedRoute.data.subscribe((data) => {
      this.portfolio = data.portfolio;
      portfolioService.getDistributorPortfolioClients(this.portfolio.id)
        .subscribe(rows => {
          this.rows = rows;
        })
    });
  }

  ngOnInit() {
    this.portfolioService.getPortfolioPositions(this.portfolio.id).subscribe({
      next: (data) => {
        this.dataPositions = data;
      },
    });
    this.permissionService.user$.subscribe((user) => {
      this.userHubs = user.hubs.map((hub) => hub.ident);
      this.gridService.getPortfolioPositionColDef(this, this.userHubs);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(cb => cb.unsubscribe())
  }

  get protectedActions() {
    return EProtectedActions;
  }

  onEditOptOut(_) {
    this.loadingOptOut$ = of(true);
    this.portfolioService
      .togglePortfolioOptOut(this.portfolio.id)
      .pipe(
        first(),
        finalize(() => {
          this.loadingOptOut$ = of(false);
        })
      )
      .subscribe({
        next: (pDto: Portfolio) => {
          this.portfolio = pDto;
        },
      });
  }
}

import {Component, Inject, ViewChild} from "@angular/core";
import {CodeTableEntry, Disclaimer, DisclaimerContent, DisclaimerService} from "../../../../../api/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../../services/global.service";
import {NotificationService} from "../../../../../services/notification.service";
import {CodeTableService} from "../../../../../services/code-table.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {ModalSubComponent} from "../../../../../models/modal.model";
import {ECodeTables, EModalType} from "../../../../../util/enum";
import {ColDef, GridOptions, RowSelectedEvent} from "ag-grid-community";
import {genCodeTableColumn, genTextColumn} from "../../../../../util/grid/grid-renderer.util";
import {genIconButtonColumn} from "../../../../../shared/grid/cell-renderers/icon-button.renderer";
import {GridComponent} from "../../../../../shared/grid/grid.component";

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html'
})
export class DisclaimerDialogComponent implements ModalSubComponent{
  disclaimer: Disclaimer;
  contents: DisclaimerContent[];
  languages: CodeTableEntry[];
  selected: DisclaimerContent;
  availableLanguages: CodeTableEntry[];

  formGroup: FormGroup;

  constructor(
    readonly fb: FormBuilder,
    readonly translateService: TranslateService,
    readonly globalService: GlobalService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
        disclaimer: Disclaimer;
        languages: CodeTableEntry[];
      } }
  ) {
    this.disclaimer = data.data.disclaimer;
    this.contents = this.disclaimer.contents!!;
    this.languages = data.data.languages;
    const group: Record<string, FormControl> = {
      name: new FormControl(
        this.disclaimer.name,
        [Validators.required, Validators.maxLength(255)]
      ),
    };
    this.languages.forEach(lang => {
      group[lang.ident] = new FormControl(
        this.disclaimer.contents?.find(c => c.language.ident == lang.ident)?.content || '',
      )
    });
    this.formGroup = fb.group(group);
    this.updateAvailableLanguages();
  }

  modalAction(modalType: EModalType) {
    const values = this.formGroup.getRawValue();
    const name = values.name;
    if (!name) {
      this.dialogRef.close();
      return;
    }
    const contents = this.contents
      .map(it => ({
        language: it.language.ident,
        content: values[it.language.ident] || ''
      }))
      .filter(it => !!it.content);
    this.dialogRef.close({
      name,
      contents,
    });
  }

  private updateAvailableLanguages() {
    this.availableLanguages = this.languages.filter(lang => !this.contents.some(it => it.language.id == lang.id))
  }

  onAdd(language: CodeTableEntry) {
    const item: DisclaimerContent = {
      language,
      content: '',
    };
    this.contents = [...this.contents, item];
    this.updateAvailableLanguages();
  }

  onDelete(data: DisclaimerContent) {
    this.contents = this.contents.filter(it => it.language.id != data.language.id);
    this.updateAvailableLanguages();
  }
}

<app-card>
  <div card-header>
    <span class="h4">{{ 'fallbackRules' | translate }}</span>
  </div>
  <div card-body [formGroup]="formGroup" *ngIf="formGroup" class="languages-form">
      <mat-form-field *ngFor="let hub of hubs">
        <mat-label>{{ hub.name }}</mat-label>
        <mat-select formControlName="{{hub.ident}}" (selectionChange)="onLangChanged($event)">
          <mat-option *ngFor="let lang of languages" [value]="lang">
            {{ lang.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
</app-card>

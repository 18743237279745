<form [formGroup]="formGroup" *ngIf="formGroup" class="modal-form">
  <div class="row row-single" >
    <mat-form-field *ngIf="hubs">
      <mat-label>{{'hub' | translate}}</mat-label>
      <mat-select formControlName="hubId" (selectionChange)="onSelectChanged($event)">
        <mat-option *ngFor="let item of hubs" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="publicationTypes">
      <mat-label>{{'publicationType' | translate}}</mat-label>
      <mat-select formControlName="publicationTypeId" (selectionChange)="onSelectChanged($event)">
        <mat-option>[{{'none' | translate}}]</mat-option>
        <mat-option *ngFor="let item of publicationTypes" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="disclaimers">
      <mat-label>{{'disclaimer' | translate}}</mat-label>
      <mat-select formControlName="disclaimerId" (selectionChange)="onSelectChanged($event)">
        <mat-option *ngFor="let item of disclaimers" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>

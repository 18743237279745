<app-hub-languages
  [selected]="fallbacks"
  (selectedChanged)="onFallbackChanged($event)"
  *ngIf="fallbacks"></app-hub-languages>
<div class="disclaimer-grid inner-padding">
  <app-card>
    <div card-header>
      <span class="h4">{{ 'disclaimer' | translate }}</span>
    </div>
    <button mat-stroked-button (click)="onAddDisclaimer()" card-action>
      <app-config-icon iconIdentifier="add"></app-config-icon>
      {{ 'add' | translate }}
    </button>
    <app-grid
      #disclaimer_grid
      tableId="disclaimer-grid"
      card-body
      [columnDefs]="columnDefs"
      [data]="data"
      [gridOptions]="gridOptions"
      [noGridFilterTopPadding]="true"
    ></app-grid>
  </app-card>
</div>
<div class="disclaimer-assignments inner-padding">
  <app-card>
    <div card-header>
      {{'disclaimer' | translate}}
    </div>
    <button mat-raised-button (click)="onAddAssignment()" card-action>
      <app-config-icon iconIdentifier="add"></app-config-icon>
      {{ 'add' | translate }}
    </button>
    <app-grid
      card-body
      #assignment_grid
      tableId="disclaimer-assignment-grid"
      [columnDefs]="assignmentsColumnDefs"
      [data]="assignmentData"
      [gridOptions]="gridOptions"
      [noGridFilterTopPadding]="true"
    ></app-grid>
  </app-card>
</div>

import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AuthConfig} from 'angular-oauth2-oidc';
import {ConfigLoaderService} from './config-loader.service';
import {first} from 'rxjs';

/**
 * Service for handling all the data that is shared inside the app. Serves as a type of store/state
 */
@Injectable({ providedIn: 'root' })
export class ConfigService {
  private defaultFilterId = '00000000-0000-0000-0000-000000000000';
  private owner: string;
  private baseFontSize: string;
  private baseFontFamily: string;
  private baseFontWeight: string;
  private baseFontColor: string;
  private primaryColor: string;
  private secondaryColor: string;
  private thridColor: string;
  private errorColor: string;
  private warningColor: string;
  private successColor: string;
  private disableColor: string;
  private generalPieColors: string[];
  private suitabilityPieColors: string[];
  private actionStatesPieColors: string[];
  private yesNoPieColors: string[];
  private yesNoHomePieColors: string[];
  private allowedHealthCheckBreachCount: number;
  private authConfigs: AuthConfig[];
  private scatterChartRiskCeiling: number | undefined = undefined;
  private scatterChartRiskFloor: number | undefined = undefined;
  private scatterChartReturnCeiling: number | undefined = undefined;
  private scatterChartReturnFloor: number | undefined = undefined;

  constructor(protected configLoaderService: ConfigLoaderService) {
    this.owner = environment.OWNER;
    this.baseFontSize = environment.BASE_FONT_SIZE;
    this.baseFontFamily = environment.BASE_FONT_FAMILY;
    this.baseFontWeight = environment.BASE_FONT_WEIGHT;
    this.baseFontColor = environment.BASE_FONT_COLOR;
    this.primaryColor = environment.PRIMARY_COLOR;
    this.secondaryColor = environment.SECONDARY_COLOR;
    this.thridColor = environment.THIRD_COLOR;
    this.errorColor = environment.ERROR_COLOR;
    this.warningColor = environment.WARNING_COLOR;
    this.successColor = environment.SUCCESS_COLOR;
    this.disableColor = environment.DISABLE_COLOR;
    this.generalPieColors = environment.GENERAL_PIE_COLORS;
    this.suitabilityPieColors = environment.SUITABILITY_PIE_COLORS;
    this.actionStatesPieColors = environment.ACTION_STATES_PIE_COLORS;
    this.yesNoPieColors = environment.YES_NO_PIE_COLORS;
    this.yesNoHomePieColors = environment.YES_NO_HOME_PIE_COLORS;
    this.allowedHealthCheckBreachCount =
      environment.ALLOWED_HEALTCHECK_BREACH_COUNT;
    this.configLoaderService
      .loadAuthConfiguration()
      .pipe(first())
      .subscribe((configs) => (this.authConfigs = configs));
  }

  getScatterChartRiskCeiling(): number {
    return this.scatterChartRiskCeiling;
  }

  getScatterChartRiskFloor(): number {
    return this.scatterChartRiskFloor;
  }

  getScatterChartReturnCeiling(): number {
    return this.scatterChartReturnCeiling;
  }

  getScatterChartReturnFloor(): number {
    return this.scatterChartReturnFloor;
  }

  getOwner(): string {
    return this.owner;
  }

  getHealthCheckBreachCount(): number {
    return this.allowedHealthCheckBreachCount;
  }

  getDefaultFilterId(): string {
    return this.defaultFilterId;
  }

  getBaseFontSize(): string {
    return this.baseFontSize;
  }

  getBaseFontFamily(): string {
    return this.baseFontFamily;
  }

  getBaseFontWeight(): string {
    return this.baseFontWeight;
  }

  getBaseFontColor(): string {
    return this.baseFontColor;
  }

  getPrimaryColor(): string {
    return this.primaryColor;
  }

  getSecondaryColor(): string {
    return this.secondaryColor;
  }

  getThridColor(): string {
    return this.thridColor;
  }

  getErrorColor(): string {
    return this.errorColor;
  }

  getWarningColor(): string {
    return this.warningColor;
  }

  getSuccessColor(): string {
    return this.successColor;
  }

  getDisableColor(): string {
    return this.disableColor;
  }

  getGeneralPieColors(): string[] {
    return this.generalPieColors;
  }

  getSuitabilityColors(): string[] {
    return this.suitabilityPieColors;
  }

  getActionStatesColors(): string[] {
    return this.actionStatesPieColors;
  }

  getYesNoPieColors(): string[] {
    return this.yesNoPieColors;
  }

  getYesNoHomePieColors(): string[] {
    return this.yesNoHomePieColors;
  }

  getAuthConfig(): AuthConfig {
    const selectedAuthConfigIndex = parseInt(
      sessionStorage.getItem('selectedAuthConfigIndex'),
      10
    );
    const configIndex = selectedAuthConfigIndex ? selectedAuthConfigIndex : 0;
    if (this.authConfigs[configIndex].redirectUri === '.cb') {
      this.authConfigs[configIndex].redirectUri =
        window.location.origin + '/callback';
    }
    return this.authConfigs[configIndex];
  }

  getAllAuths(): AuthConfig[] {
    return this.authConfigs;
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CampaignService,
  ClientWithRole,
  Intermediary,
  IntermediaryService,
  Portfolio,
  PortfolioPosition,
  PortfolioService,
  User,
  UserInfo,
} from 'src/app/api/core';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'src/app/services/notification.service';
import {ConfigService} from 'src/app/services/config.service';
import {GlobalService} from 'src/app/services/global.service';
import {ActivatedRoute} from '@angular/router';
import {finalize, first, Observable, of, Subscription} from 'rxjs';
import {ECodeTables, EViewRoutes} from 'src/app/util/enum';
import {ColDef, GridOptions, RowDoubleClickedEvent} from 'ag-grid-community';
import {CodeTableService} from 'src/app/services/code-table.service';
import {GridService} from 'src/app/services/grid.service';
import {
  genBooleanColumn,
  genCodeTableColumn,
  genEnumColumn,
  genNumberColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel,
} from 'src/app/util/grid/grid-renderer.util';
import {EProtectedActions} from '../../../util/protected-actions';
import {DEFAULT_PORTFOLIO_TABS, INTERMEDIARY_PORTFOLIO_TABS} from 'src/app/util/tab.constants';
import {Tab} from 'src/app/models/tabs.model';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {LabelBuilder} from "../../../util/label-builder";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-portfolio-overview',
  templateUrl: './portfolio-overview.component.html',
})
export class PortfolioOverviewComponent implements OnInit, OnDestroy {
  portfolio: Portfolio;
  dataClients: ClientWithRole[] = [];
  dataIntermediaries: Intermediary[] = [];
  dataPositions: PortfolioPosition[] = [];
  autoGroupColumnDef: ColDef =
    this.gridService.getPortfolioPositionAutoGroupColumnDef(this);
  tabs: Tab[] = [];
  defaultTab: string;
  activeTab: string;
  loadingOptOut$: Observable<boolean>;

  relationshipManagers: UserInfo[];

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected globalService: GlobalService,
    protected notificationService: NotificationService,
    protected configService: ConfigService,
    protected translateService: TranslateService,
    protected portfolioService: PortfolioService,
    protected campaignService: CampaignService,
    protected codeTableService: CodeTableService,
    protected labelBuilder: LabelBuilder,
    protected gridService: GridService,
    protected intermediaryService: IntermediaryService,
    protected permissionService: PermissionService,
  ) {
    activatedRoute.data.subscribe((data) => {
      this.portfolio = data.portfolio;
      this.tabs = this.portfolio.intermediary ? INTERMEDIARY_PORTFOLIO_TABS : DEFAULT_PORTFOLIO_TABS;
      this.defaultTab = this.tabs[0].text;
      this.activeTab = this.defaultTab;
    });
    this.isCidFilterAllowed = permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
  }

  portfolioClientsDefs: ColDef[] = [];

  portfolioIntermediariesDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: this.translateService.instant('name'),
      link: (data: any) => `${EViewRoutes.intermediaryOverview}${data.id}`,
    }),
    genTextColumn('externalKey', this.translateService.instant('key')),
    genEnumColumn({
      field: 'type',
      values: ['EWA', 'EAM'],
      headerName: this.translateService.instant('type'),
    }),
    genCodeTableColumn({
      field: 'businessUnit',
      headerName: this.translateService.instant('businessUnit'),
      observable: this.codeTableService.getCodeTable(ECodeTables.businessUnit),
      filterHubs: () => this.userHubs,
    }),
    genCodeTableColumn({
      field: 'hub',
      headerName: this.translateService.instant('hub'),
      observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      filterHubs: () => this.userHubs,
    }),
    {
      ...genUserEnumColumn(
        'leadRelationshipManager.username',
        this.translateService.instant('leadRelationshipManager'),
        this.fetchRelationshipManagers.bind(this),
        () => this.relationshipManagers
      ),
      valueFormatter: (r) => usernameValueLabel(r.data.leadRelationshipManager),
    },
    {
      ...genBooleanColumn(
        'closed',
        this.translateService.instant('CLOSED'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
  ];

  portfolioPositionDefs: ColDef[] = [];
  private isCidFilterAllowed: boolean;

  GridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.clientOverview}${event.data.id}`
      ),
    onGridReady: () => this.portfolioGridReady(),
  };

  PositionGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    groupDefaultExpanded: 1,
    suppressAggFuncInHeader: true,
  };
  subscriptions: Subscription[] = [];
  userHubs: string[];

  ngOnInit(): void {
    if (this.portfolio.intermediary) {
      this.portfolioService
        .getIntermediaries(this.portfolio.id)
        .pipe(first())
        .subscribe({
          next: (portfolioIntermediaries: Intermediary[]) => {
            this.dataIntermediaries = portfolioIntermediaries;
          },
        });
    } else {
      this.portfolioService
        .getClientsForPortfolio(this.portfolio.id)
        .pipe(first())
        .subscribe({
          next: (portfolioClients: ClientWithRole[]) => {
            this.dataClients = portfolioClients;
          },
        });
    }
    this.portfolioService.getPortfolioPositions(this.portfolio.id).subscribe({
      next: (data) => {
        this.dataPositions = data;
      },
    });
    this.subscriptions.push(
      this.permissionService.user$.subscribe(u => {
        this.userHubs = u.hubs.map(u => u.ident);
        this.portfolioPositionDefs =
          this.gridService.getPortfolioPositionColDef(this, this.userHubs);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private fetchRelationshipManagers(params: any) {
    this.intermediaryService.getLeadRelationshipManagers().subscribe((data) => {
      this.relationshipManagers = data;
      params.success(data.map((d) => d.username));
    });
  }

  usernameLabel(u: User) {
    return usernameValueLabel(u);
  }

  get protectedActions() {
    return EProtectedActions;
  }

  onEditOptOut(_) {
    this.loadingOptOut$ = of(true);
    this.portfolioService
      .togglePortfolioOptOut(this.portfolio.id)
      .pipe(
        first(),
        finalize(() => {
          this.loadingOptOut$ = of(false);
        })
      )
      .subscribe({
        next: (pDto: Portfolio) => {
          this.portfolio = pDto;
        },
      });
  }

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }

  private genClientColumnDefs(isCidFilterAllowed: boolean): ColDef[] {
    return [
      genLinkColumn({
        field: 'personNumber',
        headerName: this.translateService.instant('personNumber'),
        link: (data: any) => `${EViewRoutes.clientOverview}${data.id}`,
      }),
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'fullName',
          headerName: this.translateService.instant('fullName'),
          autoCompleteParams: this.dataClients.map(d => d.fullName),
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'firstName',
          headerName: this.translateService.instant('firstName'),
          autoCompleteParams: this.dataClients.map(d => d.firstName),
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'lastName',
          headerName: this.translateService.instant('lastName'),
          autoCompleteParams: this.dataClients.map(d => d.lastName),
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed
      },
      genCodeTableColumn({
        field: 'type',
        headerName: this.translateService.instant('type'),
        observable: this.codeTableService.getCodeTable(ECodeTables.portfolioType),
        filterHubs: () => this.userHubs,
      }),
      genCodeTableColumn({
        field: 'gender',
        headerName: this.translateService.instant('gender'),
        observable: this.codeTableService.getCodeTable(ECodeTables.gender),
        filterHubs: () => this.userHubs,
      }),
      genNumberColumn(
        'birthYear',
        this.translateService.instant('birthyear'),
        this.globalService,
        (d: any) => (!d.value || d.value === 0 ? '' : d.value.toString())
      ),
      genCodeTableColumn({
        field: 'clientRole',
        headerName: this.translateService.instant('clientRole'),
        observable: this.codeTableService.getCodeTable(ECodeTables.clientRole),
        filterHubs: () => this.userHubs,
      }),
      {
        ...genBooleanColumn(
          'closed',
          this.translateService.instant('CLOSED'),
          this.translateService
        ), // needed because cellType inferred before transformation to text
        cellDataType: 'text',
      },
    ];
  }

  private portfolioGridReady() {
    this.portfolioClientsDefs = this.genClientColumnDefs(this.isCidFilterAllowed);
  }
}

<table class="roles-table">
  <thead>
  <tr>
    <th>{{ 'role' | translate }}</th>
    <th *ngFor="let role of statEntries">{{ role.label }}</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>{{ 'users' | translate }}</td>
    <td *ngFor="let role of statEntries">{{ role.value }}</td>
  </tr>
  </tbody>
</table>
<app-grid
  tableId="users-grid"
  [columnDefs]="usersColumnDefs"
  [rowData]="users"
  [gridOptions]="usersGridOptions"
  (gridFiltersReset)="gridFilterReset($event)"
  [noGridFilterTopPadding]="true"
></app-grid>

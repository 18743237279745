import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CampaignStatus, MonitoringBucket, MonitoringCampaign, MonitoringService} from 'src/app/api/core';
import {Observable, Subscription} from 'rxjs';
import {GridComponent} from "../../../shared/grid/grid.component";
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {I18n} from "../../../services/i18n.service";
import {ECodeTables, EViewRoutes} from "../../../util/enum";
import {
  genCodeTableColumn,
  genDateColumn,
  genEnumColumn,
  genNumberColumn, genPercentageNumberColumn,
  genTextColumn
} from "../../../util/grid/grid-renderer.util";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent,
  ValueFormatterParams
} from "ag-grid-community";
import {GlobalService} from "../../../services/global.service";
import {LabelBuilder} from "../../../util/label-builder";
import {CodeTableService} from "../../../services/code-table.service";
import {HtmlService} from "../../../services/html.service";


type DataType = "top" | "best" | "all";

@Component({
  selector: 'app-monitoring-campaign-decentralized',
  templateUrl: './monitoring-campaign-decentralized.component.html',
})
export class MonitoringCampaignDecentralizedComponent implements OnInit, OnDestroy {
  @Input() refreshEvent: Observable<string>;
  @Input() selectedBucketSubject: Observable<MonitoringBucket>;
  @ViewChild('grid_all') grid: GridComponent;
  @ViewChild('grid_best') gridBest: GridComponent;
  @ViewChild('grid_top') gridTop: GridComponent;

  columnDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: I18n.getColName('name'),
      link: (data: any) => `${EViewRoutes.monitoringCampaignDecentralizedOverview}${data.id}`,
    }),
    {
      ...genTextColumn(
        'storyName',
        I18n.getColName('storyName'),
        null,
      ),
      hide: true,
    },
    genDateColumn({
      field: 'validFrom',
      headerName: I18n.getColName('validFrom'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validFrom),
    }),
    genDateColumn({
      field: 'validTo',
      headerName: I18n.getColName('validTo'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validTo),
    }),
    {
      ...genCodeTableColumn({
        field: 'useCase',
        headerName: I18n.getColName('useCase'),
        observable: this.codeTableService.getCodeTable(ECodeTables.useCase),
        filterHubs: () => this.selectedBucket ? [this.selectedBucket.hub.ident] : [],
      }),
      hide: true,
    },
    {
      ...genEnumColumn({
        field: 'status',
        values: [
          CampaignStatus.LAUNCHED,
          CampaignStatus.FROZEN,
          CampaignStatus.CLOSED,
          CampaignStatus.DRAFT,
          CampaignStatus.TERMINATED,
        ],
        headerName: I18n.getColName('status'),
        filterParamsInfo: {customPath: 'status'},
      }),
      hide: true,
    },
    {
      ...genNumberColumn(
        'portfolioCount',
        I18n.getColName('portfolios'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true,
    },
    {
      ...genPercentageNumberColumn(
        'clientContactsPercentage',
        I18n.getColName('contacted'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'clientContacts',
        I18n.getColName('contacts'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'clientViewedPercentage',
        I18n.getColName('viewedRate'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'clientViewed',
        I18n.getColName('viewed'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'executionsPercentage',
        I18n.getColName('executedRate'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'executions',
        I18n.getColName('executions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0),
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'noActionsPercentage',
        I18n.getColName('noActionsRate'),
        this.globalService
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'noActions',
        I18n.getColName('noActions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true,
    },
    {
      ...genPercentageNumberColumn(
        'pendingActionsPercentage',
        I18n.getColName('pendingActionsRate'),
        this.globalService
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'pendingActions',
        I18n.getColName('pendingActions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genNumberColumn(
        'participationCount',
        I18n.getColName('participationCount'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'participationPercentage',
        I18n.getColName('participationPercentage'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'sellVolume',
        this.labelBuilder.labelWithCurrency('sellTradingVolume'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 2)
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'buyVolume',
        this.labelBuilder.labelWithCurrency('buyTradingVolume'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 2)
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'totalVolume',
        this.labelBuilder.labelWithCurrency('totalTradingVolume'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 2)
      ),
    },
    {
      ...genNumberColumn(
        'clientCount',
        I18n.getColName('clients'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0),
      ),
      hide: true,
    },
    {
      ...genDateColumn({
        field: 'launchedAt',
        headerName: I18n.getColName('launchedAt'),
        dateFormatter: (params: ValueFormatterParams) =>
          this.globalService.dateToFrChLocale(params.data.launchedAt),
      }),
      hide: true,
    },
    {
      ...genDateColumn({
        field: 'createdAt',
        headerName: I18n.getColName('monitoringCachedAt'),
        dateFormatter: (params: ValueFormatterParams) =>
          this.globalService.dateToFrChLocale(params.data.createdAt),
      }),
      hide: true,
    },
    {
      ...genCodeTableColumn({
        field: 'publicationType',
        headerName: I18n.getColName('publicationType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.publicationType),
        filterHubs: () => this.selectedBucket ? [this.selectedBucket.hub.ident] : [],
      }),
      hide: true
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    columnDefs: this.columnDefs,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.monitoringCampaignOverview}${event.data.id}`
      ),
    onGridReady: (event: GridReadyEvent) => {
      this.gridApi = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.dataType = this._dataType;
    }
  };
  gridOptionsBest: GridOptions = {
    rowHeight: 36,
    columnDefs: this.columnDefs,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.monitoringCampaignOverview}${event.data.id}`
      ),
    onGridReady: (event: GridReadyEvent) => {
      this.gridApiBest = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.dataType = this._dataType;
    }
  };
  gridOptionsTop: GridOptions = {
    rowHeight: 36,
    columnDefs: this.columnDefs,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.monitoringCampaignOverview}${event.data.id}`
      ),
    onGridReady: (event: GridReadyEvent) => {
      this.gridApiTop = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.dataType = this._dataType;
    }
  };

  rowData: MonitoringCampaign[] = [];
  _dataType: DataType = 'top';
  gridApi: GridApi;
  gridApiBest: GridApi;
  gridApiTop: GridApi;
  topAmount = 5;

  selectedBucket: MonitoringBucket;
  subscriptions: Subscription[] = [];

  constructor(
    protected translateService: TranslateService,
    protected globalService: GlobalService,
    protected labelBuilder: LabelBuilder,
    protected codeTableService: CodeTableService,
    protected htmlService: HtmlService,
    protected monitoringService: MonitoringService,
  ) {
  }

  ngOnInit(): void {
    if (this.refreshEvent) {
      this.subscriptions.push(this.refreshEvent.subscribe(this.onRefresh.bind(this)));
    }
    if (this.selectedBucketSubject) {
      this.subscriptions.push(
        this.selectedBucketSubject.subscribe(bucket => {
          this.selectedBucket = bucket;
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private onRefresh(event: string) {
    // TODO implement, it receives the event ['refreshData']
  }

  gridRefresh = () => {
    // TODO check for updating
    switch (this.dataType) {
      case 'all':
        this.grid.refresh(true);
        break;
      case 'best':
        this.gridBest.refresh(true);
        break;
    }
  }

  get dataType() {
    return this._dataType;
  }

  set dataType(value: DataType) {
    this._dataType = value;
    switch (value) {
      case 'top':
        this.updateTop();
        break;
      case 'best':
        this.updateBest();
        break;
      case 'all':
        this.updateAll();
        break;
    }
  }

  private updateTop() {
    this.htmlService.waitFor(
      () => !!this.gridTop?.gridApi,
      () => {
        this.monitoringService.getDecentralizedCampaignsTop(
          this.topAmount
        ).subscribe(page => {
          this.rowData = page.pageItems;
          // necessary that top grid is rendered the same as the other grids when empty
          setTimeout(() => this.gridTop.gridApi.sizeColumnsToFit());
        });
        this.gridTop.gridApi.applyColumnState({
          state: this.gridTop.gridApi.getColumnState().map(cs => {
            cs.sort = (cs.colId === 'participationPercentage') ? 'desc' : null;
            return cs;
          }),
        });
      })
  }

  private updateBest() {
    this.htmlService.waitFor(
      () => !!this.gridBest,
      () => {
        this.gridBest.updateDataSource(this.monitoringService.getDecentralizedCampaignsBest.bind(
          this.monitoringService,
        ));
        this.htmlService.waitFor(
          () => !!this.gridBest.gridApi,
          () => {
            this.gridBest.dataSource.initDatasource(this.gridBest.gridApi);
            this.gridBest.gridApi.applyColumnState({
              state: this.gridBest.gridApi.getColumnState().map(cs => {
                cs.sort = (cs.colId === 'participationPercentage') ? 'desc' : null;
                return cs;
              }),
            });
          }
        );
      }
    );
  }

  private updateAll() {
    this.htmlService.waitFor(
      () => !!this.grid,
      () => {
        this.grid.updateDataSource(this.monitoringService.getDecentralizedCampaignsAll.bind(
          this.monitoringService,
        ));
        this.htmlService.waitFor(
          () => !!this.grid.gridApi,
          () => {
            this.grid.dataSource.initDatasource(this.grid.gridApi);
            this.grid.gridApi.applyColumnState({
              state: this.grid.gridApi.getColumnState().map(cs => {
                cs.sort = (cs.colId === 'launchedAt') ? 'desc' : null;
                return cs;
              }),
            });
          }
        )
      }
    );
  }
}

<form [formGroup]="formGroup" *ngIf="formGroup" class="modal-form">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'name' | translate}}</mat-label>
      <input matInput formControlName="name" type="text" />
    </mat-form-field>
  </div>
  <button mat-stroked-button  [matMenuTriggerFor]="menuContent">
    <app-config-icon iconIdentifier="add"></app-config-icon>
    {{ 'disclaimerAddContent' | translate }}
  </button>
  <mat-menu #menuContent="matMenu">
    <button mat-menu-item *ngFor="let lang of availableLanguages" (click)="onAdd(lang)">{{lang.ident}}: {{lang.name}}</button>
  </mat-menu>
</form>
<div class="contents modal-form">
  <form [formGroup]="formGroup" *ngIf="formGroup" class="modal-form">
    <div class="row row-single" *ngFor="let cnt of contents">
      <mat-form-field>
        <mat-label>
          {{cnt.language.name}}
        </mat-label>
        <textarea matInput [formControlName]="cnt.language.ident" rows="4" maxlength="1000"></textarea>
      </mat-form-field>
      <app-config-icon iconIdentifier="delete" (click)="onDelete(cnt)"></app-config-icon>
    </div>
  </form>
</div>

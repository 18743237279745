<div class="grid-filter-item">
  <div class="grid-filter-item-title h4">{{ filterConfig.name }}</div>
  <div class="grid-filter-item-content">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'searchOoo' | translate }}</mat-label>
      <!-- Disable search-string if there are no options to filter, this happens only in client-side data grids -->
      <input matInput [(ngModel)]="searchString" (ngModelChange)="onSearchStringUpdated()" [disabled]="options.length === 0"/>
    </mat-form-field>
    <div class="grid-filter-item-option-list" [formGroup]="setFilterForm">
      <mat-checkbox
        *ngIf="filteredOptions.length > 0"
        color="primary"
        [checked]="selectedAll"
        (change)="onSelectAllChanged($event)"
      >
        <span>{{ 'selectAll' | translate }}</span>
      </mat-checkbox>
      <mat-checkbox
        *ngFor="let option of filteredOptions"
        color="primary"
        [checked]="setFilterForm.controls[option.key].value"
        [formControlName]="option.key"
      >
        <span [class.color-warning]="option.closed">
          <app-config-icon *ngIf="option.icon"
            [iconIdentifier]="option.icon"
            [ngClass]="option.iconClass">
          </app-config-icon>
          {{ option.key }}
        </span>
      </mat-checkbox>
    </div>
  </div>
  <button mat-flat-button class="grid-filter-apply" color="primary" (click)="onApplyFilter()">
    {{'apply' | translate}}
  </button>
</div>
